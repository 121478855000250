<template>
  <div class="home">
    <v-skeleton-loader
      height="100vh"
      :loading="loading"
      type="image, image, image, image"
    >
      <v-container fluid class="fill-height pa-0">
        <v-carousel v-model="model" height="93vh" hide-delimiter-background>
          <v-carousel-item v-for="(product, i) in products" :key="i">
            <v-img :src="product.src" class="align-center" height="100%">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-btn text :to="product.route" x-large>
                    <div
                      :class="`${product.titleColor}--text text-h3 ${product.font}`"
                    >
                      {{ product.title }}
                    </div>
                  </v-btn>
                </v-col>
                <v-col v-if="product.subtitle" cols="12" class="pt-0">
                  <v-btn
                    text
                    :color="product.subtitleColor"
                    :to="product.route"
                  >
                    <div class="text-h6">
                      {{ product.subtitle }}
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-container>
    </v-skeleton-loader>
    <!-- <v-container fluid class="fill-height pa-0">
      <v-carousel v-model="model" height="93vh" hide-delimiter-background>
        <v-carousel-item v-for="(product, i) in products" :key="i">
          <v-img :src="product.src" class="align-center" height="100%">
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-btn text :to="product.route">
                  <div
                    :class="
                      `${product.titleColor}--text text-h4 ${product.font}`
                    "
                  >
                    {{ product.title }}
                  </div>
                </v-btn>
              </v-col>
              <v-col v-if="product.subtitle" cols="12" class="pt-0">
                <v-btn text :color="product.subtitleColor" :to="product.route">
                  <div class="text-subtitle">
                    {{ product.subtitle }}
                  </div>
                </v-btn>
              </v-col>
            </v-row>
            <v-btn :to="product.route">
              <div :class="`${product.color}--text text-h5`">
                {{ product.title }}
              </div>
            </v-btn>
            <v-btn text :to="product.route">
              <div :class="`${product.color}--text text-caption`">
                {{ product.subtitle }}
              </div>
            </v-btn>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-container> -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      model: 0,
      loading: true,
      transition: "scale-transition",
      products: [
        {
          title: "Play & Think",
          subtitle: "선생님과 함께",
          src: "/boys.jpg",
          route: "/play-and-think",
          titleColor: "white",
          subtitleColor: "white",
          font: "font-italic",
        },
        {
          title: "한글밥상",
          src: "/sejong.jpg",
          subtitle: "선생님과 함께",
          route: "/hangeul",
          titleColor: "black",
          subtitleColor: "black darken-3",
          font: "font-weight-medium",
        },
        {
          title: "Play & Think",
          subtitle: "부모님과 함께",
          src: "/girlAndMom.jpg",
          route: "/parents",
          titleColor: "light-green",
          subtitleColor: "light-green darken-3",
          font: "font-italic",
        },
      ],
    };
  },
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style>
.v-responsive__content {
  text-align: center;
}
</style>
