<template>

  <div class="admin">

    <v-container class="pa-0">

      <v-tabs color="deep-purple accent-4" right>

        <v-tab>회원</v-tab>

        <v-tab>유치원/학급</v-tab>

        <v-tab>기타</v-tab>

        <v-tab-item class="mt-5">

          <v-container class="pa-0">

            <v-row>

              <!-- 기관 회원 보여주기 -->

              <v-col cols="12" md="6">

                <v-card dark color="teal lighten-3" class="pa-3">

                  <v-card-title>
                     기관(총 {{ kindergartens.length }}명)
                  </v-card-title>

                </v-card>

                <v-list two-line>

                  <v-list-item-group active-class="grey--text" multiple>

                    <template v-for="(user, index) in kindergartens">

                      <v-list-item>

                        <template v-slot:default="{ active }">

                          <v-list-item-action v-if="!active" class="grey--text">
                             {{ index + 1 }}
                          </v-list-item-action>

                          <v-list-item-action
                            v-else
                            class="pink--text text-lighten-3"
                          >
                             {{ index + 1 }}
                          </v-list-item-action>

                          <v-list-item-content>

                            <v-list-item-title
                              v-text="user.alias"
                            ></v-list-item-title>

                            <v-list-item-subtitle>
                               {{ user.email }}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle>

                              <v-chip
                                outlined
                                v-for="level in user.levelOfEnglish"
                                :key="level"
                                class="mr-2 teal--text"
                              >
                                 {{ level }}
                              </v-chip>
                               /
                              <v-chip
                                outlined
                                v-for="level in user.levelOfKorean"
                                :key="level"
                                class="ml-2 purple--text"
                              >
                                 {{ level }}
                              </v-chip>

                            </v-list-item-subtitle>

                          </v-list-item-content>

                          <v-list-item-action>

                            <Member
                              v-if="!active"
                              color="grey lighten-1"
                              icon="mdi-card-account-details-outline"
                              :member="user"
                            />

                            <Member
                              v-else
                              color="pink lighten-1"
                              icon="mdi-card-account-details"
                              :member="user"
                            />

                          </v-list-item-action>

                        </template>

                      </v-list-item>

                      <v-divider
                        v-if="index < kindergartens.length - 1"
                        :key="index"
                      ></v-divider>

                    </template>

                  </v-list-item-group>

                </v-list>

                <!-- <v-card
                  class="pa-3"
                  v-for="(user, i) in kindergartens"
                  :key="i"
                >
                  <v-card-title
                    ><span class="teal--text mr-3">{{ i + 1 }}</span>
                    {{ user.alias }}
                  </v-card-title>
                  <v-card-text>
                    <span class="green--text  ml-5">{{ user.member }}</span>
                    / {{ user.email }} / {{ user.levelOfEnglish }} /
                    {{ user.levelOfKorean }}
                  </v-card-text>
                </v-card> -->

              </v-col>

              <!-- 부모님 회원만 보여주기 -->

              <v-col cols="12" md="6">

                <v-card dark color="pink lighten-3" class="pa-3">

                  <v-card-title>부모님(총 {{ parents.length }}명)</v-card-title>

                </v-card>

                <v-list two-line>

                  <v-list-item-group active-class="grey--text" multiple>

                    <template v-for="(user, index) in parents">

                      <v-list-item>

                        <template v-slot:default="{ active }">

                          <v-list-item-action v-if="!active" class="grey--text">
                             {{ index + 1 }}
                          </v-list-item-action>

                          <v-list-item-action
                            v-else
                            class="pink--text text-lighten-3"
                          >
                             {{ index + 1 }}
                          </v-list-item-action>

                          <v-list-item-content>

                            <v-list-item-title
                              v-text="user.alias"
                            ></v-list-item-title>

                            <v-list-item-subtitle>
                               {{ user.email }}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle>

                              <v-chip
                                outlined
                                v-for="level in user.levelOfEnglish"
                                :key="level"
                                class="mr-2 teal--text"
                              >
                                 {{ level }}
                              </v-chip>
                               /
                              <v-chip
                                outlined
                                v-for="level in user.levelOfKorean"
                                :key="level"
                                class="ml-2 purple--text"
                              >
                                 {{ level }}
                              </v-chip>

                            </v-list-item-subtitle>

                          </v-list-item-content>

                          <v-list-item-action>

                            <Member
                              v-if="!active"
                              color="grey lighten-1"
                              icon="mdi-card-account-details-outline"
                              :member="user"
                            />

                            <Member
                              v-else
                              color="pink lighten-1"
                              icon="mdi-card-account-details"
                              :member="user"
                            />

                          </v-list-item-action>

                        </template>

                      </v-list-item>

                      <v-divider
                        v-if="index < parents.length - 1"
                        :key="index"
                      ></v-divider>

                    </template>

                  </v-list-item-group>

                </v-list>

              </v-col>

            </v-row>

          </v-container>

        </v-tab-item>

        <v-tab-item>

          <v-container fluid>

            <v-row>

              <v-col cols="12" md="6">

                <v-card outlined class="pa-5">

                  <v-text-field
                    v-model="kindergarten"
                    label="어린이집/유치원/초등학교"
                    placeholder="산운유치원"
                    prepend-icon="mdi-bus-school"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="addKindergarten"
                  ></v-text-field>

                  <v-chip
                    v-for="kindergarten in kindergartenNames"
                    :key="kindergarten"
                    class="ma-2"
                    color="primary"
                    outlined
                    pill
                  >
                     {{ kindergarten }}
                    <v-icon
                      @click="deleteKindergarten(kindergarten)"
                      color="pink"
                      class="ml-2"
                    >
                       mdi-minus
                    </v-icon>

                  </v-chip>

                </v-card>

              </v-col>

              <v-col cols="12" md="6">

                <v-card outlined class="pa-5">

                  <v-text-field
                    v-model="classroom"
                    label="반 이름"
                    placeholder="행복한반"
                    prepend-icon="mdi-google-classroom"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="addClassroom"
                  ></v-text-field>

                  <v-chip
                    v-for="classroom in classroomNames"
                    :key="classroom"
                    class="ma-2"
                    color="red"
                    outlined
                    pill
                  >
                     {{ classroom }}
                    <v-icon
                      @click="deleteClassroom(classroom)"
                      color="primary"
                      class="ml-2"
                    >
                       mdi-minus
                    </v-icon>

                  </v-chip>

                </v-card>

              </v-col>

            </v-row>

          </v-container>

        </v-tab-item>

      </v-tabs>

    </v-container>

    <!-- <v-container>
            <v-row
                class="fill-height"
                justify="center"
                align="center"
            >
                <v-col
                    cols="12"
                    sm="6"
                    class="text-center"
                >
                    <v-form
                        class="pa-3"
                    >
                        <v-card
                            outlined
                            class="pa-5"
                        >
                            <v-row>
                                <v-col cols="12">
                                    <v-select
                                        :items="units"
                                        v-model="unit"
                                        label="Unit"
                                        required
                                        prepend-icon="mdi-group"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        :items="chapters"
                                        v-model="chapter"
                                        label="Chapter"
                                        required
                                        prepend-icon="mdi-book-open-page-variant-outline"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="title"
                                        label="Title"
                                        required
                                        prepend-icon="mdi-format-title"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="situation"
                                        label="Situation"
                                        required
                                        prepend-icon="mdi-calendar-edit"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="korean"
                                        label="Korean"
                                        required
                                        prepend-icon="mdi-goodreads"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="english"
                                        label="English"
                                        required
                                        prepend-icon="mdi-alphabetical"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        dark
                                        color="blue-grey"
                                        @click.prevent="submitHandle"
                                        :loading="loading"
                                    >Submit</v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-form>
                </v-col>
            </v-row>     
        </v-container>
        <v-snackbar
            v-model="snackbar"
            :vertical="vertical"
            :timeout="timeout"
        >
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue-grey"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar> -->

  </div>

</template>

<script>
 import { projectFirestore } from "../firebase/config"; import Member from "../components/Member.vue";
export default { name: "Admin", components: { Member }, data() { return { users:
[], data: [], kindergarten: "", classroom: "", kindergartenNames: [], classroomNames:
[], }; }, methods: { getUsers() { projectFirestore .collection("users") .orderBy("member")
.get() .then((snapshot) => { snapshot.forEach((doc) => { this.users.push({ ...doc.data(),
id: doc.id, }); }); }) .then(() => { }); }, getData() { projectFirestore .collection("data")
.doc("kindergartens") .onSnapshot((doc) => { this.kindergartenNames = doc.data().names.sort();
}); projectFirestore .collection("data") .doc("classrooms") .onSnapshot((doc) =>
{ this.classroomNames = doc.data().names.sort(); }); }, addKindergarten() { projectFirestore
.collection("data") .doc("kindergartens") .update({ names: [...this.kindergartenNames,
this.kindergarten], }) .then(() => { this.kindergarten = ""; }); }, addClassroom()
{ projectFirestore .collection("data") .doc("classrooms") .update({ names: [...this.classroomNames,
this.classroom], }) .then(() => { this.classroom = ""; }); }, deleteKindergarten(item)
{ const kindergartenNames = this.kindergartenNames.filter( (kindergartenName) =>
{ return kindergartenName != item; } ); projectFirestore .collection("data") .doc("kindergartens")
.update({ names: kindergartenNames, }) .then(() => { }); }, deleteClassroom(item)
{ const classroomNames = this.classroomNames.filter((classroomName) => { return classroomName
!= item; }); projectFirestore .collection("data") .doc("classrooms") .update({ names:
classroomNames, }) .then(() => { }); }, }, mounted() { this.getUsers(); this.getData();
}, computed: { parents() { return this.users.filter((user) => { return user.member
== "부모님"; }); }, kindergartens() { return this.users.filter((user) => user.member
== "기관"); }, }, };
</script>

<style></style>

