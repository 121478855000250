var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parents"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row')],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},_vm._l((_vm.levels),function(level,i){return _c('v-row',{key:i},[_vm._l((level.books),function(book,j){return [(_vm.levelOfEnglish.includes(`K-${i + 1}`))?_c('v-col',{key:j,staticClass:"pa-1",attrs:{"cols":"4","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('router-link',{attrs:{"to":{
                      name: 'VideosForParents',
                      params: {
                        level_volume: `${level.level}-${book.volume}`,
                        weeks: book.weeks,
                        steam: book.steam,
                        supplement: book.supplement,
                        mp3: book.mp3,
                        parents: book.parents,
                      },
                    }}},[_c('v-img',{staticClass:"align-end",attrs:{"src":`/img/stories/${level.level}-${book.volume}.jpg`}},[_c('v-card-title',{class:`white--text ${level.color}`},[_vm._v(" "+_vm._s(level.level)+"- "+_vm._s(book.volume != "10" ? book.volume[1] : book.volume)+" ")])],1)],1)],1)]}}],null,true)})],1):_vm._e()]})],2)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }