import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    items: [
      // level 1
      {
        level: "K1",
        color: "indigo",
        books: [
          // k1-01
          {
            volume: "01",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-01-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "I am happy !",
                              "Happy / sad",
                              "Hello, how are you?",
                              "How do you feel?",
                              "Happy, happy, I am happy.",
                              "This is how I feel.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story.",
                              "Look at me.",
                            ],
                          },
                          {
                            sentences: [
                              "I will sing with motions.",
                              "Happy birthday to you.",
                              "Happy birthday to you.",
                              "Happy birthday dear Anna.",
                              "Happy birthday to you.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "my face(prek)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book1/prek_v1_face.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "hello song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "창문을 가리키며",
                              "It is sunny",
                              "머리위로 두 손을 마주 잡으며",
                              "It is cloudy",
                              "양손으로 구름모양으로 만들며",
                              "It is rainy",
                              "양손으로 비가 내리듯 손가락을 움직이며",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k1-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Today is Anna's birthday.",
                              "Do you see a (cake/candle/hat/cup)?",
                              "Yes. I see a (cake/candle/hat/cup).",
                              "영상, 상호작용",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat.",
                            ],
                          },
                          {
                            sentences: [
                              "How do you feel?",
                              "Happy / sad ",
                              "How do you feel?",
                              "Are you happy?",
                              "I am happy",
                              "How do you feel?",
                              "Draw your face.",
                              "Excellent!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/52/52_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "I am happy !",
                              "Happy / sad",
                              "Hello, how are you?",
                              "How do you feel?",
                              "Happy, happy, I am happy.",
                              "This is how I feel.",
                              "Circle the picture",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "birthday gift",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "It's my birthday. I want a gift.",
                              "Teddy bear. I want a teddy bear!",
                              "What do you want?	",
                              "Do you want",
                              "(a book, a tea set, a camera, a teddy bear,",
                              "stickers, a ball, a toy car)?",
                              "I want (a teddy bear/ stickers /a toy car/...)",
                              "Circle the gifts that you want.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/51/51_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "hello friends",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-01-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 2,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Alphabet Song.",
                              "영상의 Alphabet을 point하며,",
                            ],
                          },
                          {
                            sentences: [
                              "Do you know the Alphabet song?",
                              "Let's sing the Alphabet song.",
                              "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "open shut them",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "wiggle wiggle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 2,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big A little a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big A Little a.",
                              "Letter A ('애')",
                              "What letter is this?",
                              "Big A and little a.",
                              "Trace the big A and little a. apple",
                              "Put the matching sticker on the circle.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Look at me.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big A little a",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big A and little a.",
                              "Point to the big A and little a.",
                              "영상, 상호작용",
                              "Say the sound of the letter A.",
                              "Find the letters big A and little a.",
                              "Circle the letters big A and little a.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/50/50_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "I am happy !",
                              "Happy / sad",
                              "Hello, how are you?",
                              "How do you feel?",
                              "Happy, happy, I am happy.",
                              "This is how I feel.",
                              "Circle the picture",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "my face",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "This is a face.",
                              "These are eyes.",
                              "How many eyes are there?",
                              "Let's count.",
                              "One, two. Two eyes.",
                              "There are two eyes.",
                              "This is a nose.",
                              "How many noses are there?",
                              "One. One nose.",
                              "There is one nose.",
                              "This is a mouth.",
                              "How many mouths are there?",
                              "One. One mouth.",
                              "There is one mouth.",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book1/prek_v1_face.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "hello song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-01-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "창문을 가리키며",
                              "It is sunny",
                              "머리위로 두 손을 마주 잡으며",
                              "It is cloudy",
                              "양손으로 구름모양으로 만들며",
                              "It is rainy",
                              "양손으로 비가 내리듯 손가락을 움직이며",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets have tea together",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's Have Tea Together",
                              "How many cups do we need?",
                              "영상, 상호작용",
                              "How many cups do we need?",
                              "We need two cups.",
                              "Circle the cups we need.",
                              "Trace the number in the box.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Minji, come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book1/k1_v1_teaTogether.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "wiggle wiggle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "treasure hunt",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's play a game.",
                              "Find the birthday hat.",
                              "영상, 상호작용",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_01.html",
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-2",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big A little a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Big A Little a",
                              "Let's sing: Big A Little a.",
                              "Letter A ('애')",
                              "What letter is this?",
                              "Big A and little a. apple",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hello friends",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big B little b(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big B Little b.",
                              "Letter B (‘브')",
                              "What letter is this?",
                              "Big B and little b.",
                              "Trace the big B and little b.",
                              "banana.",
                              "Put the matching sticker on the circle.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big B little b",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen and find the letters",
                              "Find the letters big B and little b.",
                              "영상, 상호작용",
                              "Point to the big B and little b.",
                              "Say the sound of the letter B.",
                              "Find the letters big B and little b.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/48/48_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-01-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "what do you see(k1-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What Do You See?",
                              "What is hot and what is cold?",
                              "What is hot ?",
                              "If it is hot, color it red.",
                              "It's hot.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/71/71_01.html",
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "green",
                        imageURL: "",
                        videoTitle: "lets count(k1-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book1/prek_v1_count.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "patterns(k1-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you see patterns?",
                              "Circle, square, circle, square.",
                              "What comes next?",
                              "(Triangle) comes next.",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                              "영상, 상호작용",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/47/47_01.html",
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "green",
                        imageURL: "",
                        videoTitle: "Simon says(k1-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Listen and touch your body parts.",
                              "Listen carefully and move.",
                              "영상, 상호작용",
                              "Simon says, touch your _______ !",
                              "ears! / nose! / eyes! / mouth!",
                              "Put the sticker on the circle when you are done.",
                              "Good job!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/93/93_01.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "hello friends",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "happy birthday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "생일 축하합니다.",
                              "생일 축하합니다.",
                              "사랑하는 애나의 생일 축하합니다.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "lets make bubbles",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's Make Bubbles!",
                              "Can you make bubbles?",
                              "What do we need?",
                              "a soap / an apron / a washbowl",
                              "Wash your hands with soap.",
                              "Do you see bubbles?",
                              "Put the bubbles stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/92/92_01.html",
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Happy Birthday Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "green",
                        imageURL: "",
                        videoTitle: "none",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "wiggle wiggle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-01.jpg?alt=media&token=a0d76e0f-cde7-4c53-81fe-25c067ded822",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-01%20lets%20make.mp4?alt=media&token=1598a76f-a7b4-45e6-b30a-c7b0c1dac209",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-01.png?alt=media&token=47cc35ab-11ff-49fc-94ed-849d1a322a30",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book1/k1_v1_howTo.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book1/k1_v1_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-01.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book1/k1_v1_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-01.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Daily Routine Manual",
                  color: "grey lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 1 Manual",
                  color: "lime lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 2 Manual",
                  color: "teal lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "happy birthday story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "happy birthday song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "hello song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "wiggle wiggle",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "amber",
                imageURL: "",
                videoTitle: "hello friends",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "head and shoulders",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "feelings",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "orange",
                imageURL: "",
                videoTitle: "big A little a(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "orange",
                imageURL: "",
                videoTitle: "big B little b(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Alphabet Song",
                color: "orange",
                imageURL: "",
                videoTitle: "the alphabet song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k1-02
          {
            volume: "02",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-02-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Look out the window.",
                              "창문을 가리키며",
                              "It is sunny",
                              "머리위로 두 손을 마주 잡으며",
                              "It is cloudy",
                              "양손으로 구름모양으로 만들며",
                              "It is rainy",
                              "양손으로 비가 내리듯 손가락을 움직이며",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "our farm",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book2/prek_v2_farm.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "teddy bear",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "I am happy !",
                              "Happy /  sad  / angry",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                              "beehive, bees, sees, hive, five",
                              "Put the beehive and bee stickers on the circles.",
                              "Good job",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k1-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see?",
                              "영상, 상호작용",
                              "The boy and girl are looking for animals.",
                              "There is a (dog / beehive) in the picture.",
                              "Do you see a (dog / butterfly / beehive / bee)?",
                              "Yes/no. I see a dog and a beehive.",
                              "Circle the pictures.",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                            sentences: [
                              "Here is the beehive.",
                              "Do you see bees in the pictures?",
                              "Can you draw bees?",
                              "Draw bees.",
                              "Excellent!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/46/46_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "animal song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big B little b(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big B Little b.",
                              "Letter B (‘브')",
                              "What letter is this?",
                              "Big B and little b.",
                              "banana.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets act it out(Stella)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Can you make (bee / bird) sounds?",
                              "Buzz! / Tweet!",
                              "영상. 상호작용",
                              "Can you make animal sounds?",
                              "Bee say, Buzz!...",
                              "bird say, Tweet! …",
                              "Put the matching stickers on the circles.",
                              "Good Job",
                            ],
                          },
                          {
                            sentences: [
                              "Can you move like animals?",
                              "Let's pretend.",
                              "Can you fly like a butterfly?",
                              "fly like a butterfly",
                              "Can you bite like a squirrel?",
                              "bite like a squirrel",
                              "Put the matching stickers on the circles.",
                              "Good Job",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/89/89_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "morning",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-02-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Alphabet Song.",
                              "영상의 Alphabet을 point하며,",
                            ],
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Here Is the Beehive Song",
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big C little c(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big C Little c.",
                              "Letter C (크')",
                              "What letter is this?",
                              "Big C and little c.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "morning",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Morning",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big C little c",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen and find the letters",
                              "Find the letters big C and little c.",
                              "Point to the big C and little c.",
                              "Say the sound of the letter C.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/45/45_01.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "lets count(k1-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: ["Count 2 and 3"],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book2/prek_v2_count.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "open shut them",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Open Shut Them",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "창문을 가리키며",
                              "It is sunny",
                              "머리위로 두 손을 마주 잡으며",
                              "It is cloudy",
                              "양손으로 구름모양으로 만들며",
                              "It is rainy",
                              "양손으로 비가 내리듯 손가락을 움직이며",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "count the animals",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "I see (birds / butterflies / bees).",
                              "How many animals are there?",
                              "영상",
                            ],
                          },
                          {
                            sentences: [
                              "How many (birds) are there?",
                              "Let's count.",
                              "One, two, three. three birds.",
                              "This is a butterflies",
                              "How many butterflies?",
                              "Four, four butterflies.",
                              "There is four butterflies.",
                              "This is a bees",
                              "How many bees?",
                              "Five, five bees.",
                              "There is five bees.",
                              "One, two, three, four, five",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/44/44_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "teddy bear",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-02-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "How do you feel?",
                              "Happy / sad / angry",
                              "Are you happy?",
                              "I am happy",
                              "Circle the picture.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "patterns(k1-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's look at the bee.",
                              "The bee has a pattern.",
                              "Yellow, black, yellow, black.",
                              "Patterns 영상.",
                              "The bee has a pattern.",
                              "Yellow, black, yellow, black.",
                              "What comes next?",
                              "Color in the box.",
                              "Yellow comes next.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book2/k1_v2_pattern.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "teddy bear",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "what am i(k1-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Listen to the animal sounds.",
                              "What Am I ?",
                              "What is it?",
                              "Can you make ( cat / dog / pig ) sounds?",
                              "Meow! Woof! Oink!",
                              "I am a ( cat /dog / pig ).",
                              "I say ( meow / woof / oink ).",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Let's find the same animals.",
                              "Bee / dog/ cat / bird / squirrel",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_148.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big C little c(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big C Little c.",
                              "Letter C (＇크' )",
                              "What letter is this?",
                              "Big C and little c.",
                              "cookie.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "animal song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 2,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big D little d(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big D Little d.",
                              "Letter D (‘드')",
                              "What letter is this?",
                              "Big D and little d.",
                            ],
                          },
                          {
                            sentences: [
                              "Trace the Big D and little d.",
                              "Put the matching sticker on the circle.",
                              "Trace the little d in the word doughnut.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big D little d",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen and find the letters",
                              "Find the letters big D and little d.",
                              "Point to the big D and little d.",
                              "Say the sound of the letter D.",
                              "Find the letters big D and little d.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/43/43_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "walking walking",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Walking, Walking",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-02-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "what do you see(k1-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you see animals or plants?",
                              "What Do You See? 영상.",
                              "Rabbit / Cat",
                              "Do you see animals or plants?",
                              "Find and color only the animals.",
                              "Good job!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/42/42_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "영상.",
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "animal song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "circle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Circle",
                              "Draw a bee with the circles.",
                              "head / body / wing /",
                              "Circle 영상",
                              "Draw a bee with the circles.",
                              "head / body / wing /",
                              "Trace the circles and draw wings on it.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/66/66_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "Simon says(k1-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's be Robby.",
                              "Listen and do the actions.",
                              "Simon Says…",
                            ],
                            sentences: [
                              "Simon says, Jump like a dog!",
                              "Jump like a dog!",
                              "Run like a cat!",
                              "Sting like a bee!",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_149.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "teddy bear",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "here is the beehive story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "여기 벌집이 있네요.",
                              "그런데 벌은 어디 있나요?",
                              "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                              "저것 보세요. 벌집에서 나오네요.",
                              "하나, 둘, 셋, 넷, 다섯,",
                              "윙~!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "here is the beehive song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Here Is the Beehive Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "green",
                        imageURL: "",
                        videoTitle: "bumblebees(Stella)",
                        videoURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/90/vid/9001.mp4",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's chant and do a finger play.",
                              "Can you guess which finger stung your neck?",
                              "Bumblebees",
                              "all around, bumblebee, finger, guess, sting",
                            ],
                          },
                          {
                            sentences: [
                              "Let's chant and do a finger play.",
                              "Bumblebees / Bumblebees",
                              "Buzz all around.",
                              "Who‘s going to sting?",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "walking walking",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-02.jpg?alt=media&token=6cc5bdd3-5ce9-40b0-a76f-38d0f6716b42",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-02%20lets%20make.mp4?alt=media&token=067f5785-5731-499f-9a25-ad7f425f3783",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-02.png?alt=media&token=88fafde2-0f64-423c-8fb5-564ed160d8dd",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book2/k1_v2_howTo.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book2/k1_v2_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-02.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book2/k1_v2_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-02.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-02%20teacher%20supplement.jpg?alt=media&token=81011f86-c7ee-4361-982a-bb1f395bd879",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-02%20teacher%20supplement.pdf?alt=media&token=2c446e44-3b92-45c1-b46f-ef03e28d2fe4",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-02%20worksheets.jpg?alt=media&token=207a23f6-9358-4fae-9ad0-06544e38f35d",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-02%20worksheets.pdf?alt=media&token=0e1aba62-b7ea-4af2-a187-14617751294b",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "here is the beehive story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "here is the beehive song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "morning",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "animal song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "green",
                imageURL: "",
                videoTitle: "walking walking",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "teddy bear",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "the weather song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "pink",
                imageURL: "",
                videoTitle: "big C little c(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "big D little d(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "ABC Song",
                color: "green",
                imageURL: "",
                videoTitle: "ABC song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k1-03
          {
            volume: "03",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-03-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "hello song daily routine",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Say hello to your friends!",
                              "Hello, teacher!",
                            ],
                          },
                          {
                            sentences: [
                              "Hello, hello. Say it high. (Hi! Hi! Hi!)",
                              "Hello, hello. Say it low. (Hello. Hello. Hello.)",
                              "Hello, hello. Say it fast. (Hello, hello, hello, hello, hello.)",
                              "Hello, hello. Say it slow. (Hello~. Hello~.)",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "hand print",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book3/prek_v3_fingerPrint.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "창문을 가리키며",
                              "It is sunny",
                              "머리위로 두 손을 마주 잡으며",
                              "It is cloudy",
                              "양손으로 구름모양으로 만들며",
                              "It is rainy",
                              "양손으로 비가 내리듯 손가락을 움직이며",
                            ],
                          },
                          {
                            sentences: [
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "baby, daddy, mommy",
                              "Put Daddy and Mommy stickers on the circles.",
                              "Fishy Fishy in a Brook Song",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k1-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see?",
                              "There is a fish and a worm.",
                              "Do you see a (fish/worm/frog/bear)?",
                              "Yes/no.",
                              "I see a fish and a worm.",
                              "Circle the pictures.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                          {
                            sentences: [
                              "Fishy Fishy in a Brook",
                              "Is the fish going to eat the worm?",
                              "Draw the fish.",
                              "Do you see fish in the picture?",
                              "Excellent!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/25/25_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "hello friends",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big D little d(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big D Little d.",
                              "Letter D (‘드')",
                              "What letter is this?",
                              "Big D and little d.",
                              "doughnut",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets act it out(k1-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Mommy is cooking.",
                              "Can you cook like Mommy?",
                            ],
                          },
                          {
                            sentences: [
                              "Can you act like Mommy?",
                              "Does she need a(n)",
                              "(pan / oven mitt / apron / fishing pole)?",
                              "Circle the things Mommy needs.",
                              "She needs a pan, an oven mitt, and an apron.",
                              "Good Job",
                            ],
                          },
                          {
                            sentences: [
                              "Can you fish like Daddy?",
                              "Does he need a(n)",
                              "(pan/oven mitt/apron/fishing pole)?",
                              "He needs a fishing pole and a worm.",
                              "Circle the things Daddy needs.",
                              "Good Job !",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/26/26_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "finger family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-03-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Alphabet Song.",
                              "영상의 Alphabet을 point하며,",
                            ],
                          },
                          {
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN",
                              "OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "lets count(k1-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book3/prek_v3_count.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "hello song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big E little e(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Big E Little e.",
                              "Let's sing Big E little e.",
                              "Letter E (‘에')",
                              "What letter is this?",
                              "Big E and little e.",
                            ],
                          },
                          {
                            sentences: [
                              "Trace the Big E and little e.",
                              "Put the matching sticker on the circle.",
                              "Trace the little e in the word egg.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big E little e",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen and find the letters",
                              "Find the letters big E and little e.",
                              "Point to the big E and little e.",
                              "Say the sound of the letter E.",
                              "Circle the letters big E and little e.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/35/35_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "I am happy !",
                              "Happy / sad",
                              "Hello, how are you?",
                              "How do you feel?",
                              "Happy, happy, I am happy.",
                              "This is how I feel.",
                              "Circle the picture",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "bear family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Bear Family.",
                              "This is a bear family.",
                              "This is (Mommy/Daddy/Baby).",
                              "Point to (Mommy/Daddy/Baby).",
                            ],
                          },
                          {
                            sentences: [
                              "How many bears are there?",
                              "Let's count.",
                              "One, two, three.",
                              "There are three bears.",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/32/32_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "happy family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-03-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets make fish",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's make fish.",
                              "Can you make fish with shapes?",
                              "This is a ( circle / square / triangle )",
                              "영상, 상호작용",
                            ],
                          },
                          {
                            sentences: [
                              "Can you make fish with shapes?",
                              "This is a ( circle / square / triangle )",
                              "Let's make a fish.",
                              "Let's make a (head/tail) with a (circle/triangle).",
                              "Make your own fish with stickers.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book3/k1_v3_pattern.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "finger family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "walk around the garden(Stella)",
                        videoURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/88/vid/8801.mp4",
                        teachingGuides: [
                          {
                            sentences: [
                              "Walk Around the Garden",
                              "Let's chant and do a finger play.",
                              "영상.활동",
                              "Walk around the garden like a teddy bear.",
                              "One step. Two step.",
                              "Tickling / Tickling",
                              "Tickle you everywhere.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big E little e(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big E little e.",
                              "Letter E (‘에' )",
                              "What letter is this?",
                              "Big E and little e.",
                              "egg",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "teddy bear",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big F little f(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big F little f.",
                              "Letter F (‘프어')",
                              "What letter is this?",
                              "Big F and little f.",
                              "Trace the Big F and little f.",
                              "Put the matching sticker on the circle.",
                              "Trace the little f in the word fish.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big F little f",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big F and little f.",
                              "영상, 상호작용.",
                              "Point to the big F and little f.",
                              "Say the sound of the letter F.",
                              "Find the letters big F and little f.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/36/36_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "happy family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Happy Family Song",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-03-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "what do you see(k1-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you see daddy's or mommy's things?",
                              "영상. 상호작용",
                              "Do you see daddy's or mommy's things?",
                              "Find and color mommy's things",
                              "Good job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/37/37_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "finger family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "cookie cutters",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What shape do you see?",
                              "Fish / triangle / circle / square",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/40/40_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "Simon says(k1-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's be Teddy Bear.",
                              "Listen and do the actions.",
                            ],
                            sentences: [
                              "Simon says,",
                              "stand UP!/ Touch the ground!",
                              "Turn around!",
                              "Stand up/ Touch the ground",
                              "Turn around",
                              "Listen and do the actions.",
                              "Good job!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_150.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "happy family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시냇가의 물고기를",
                              "아빠는 낚시해서 잡고",
                              "엄마는 팬에 튀기고",
                              "아기는 남자답게 씩씩하게 먹었어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "bubbles bubbles bubbles(Stella)",
                        videoURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/86/vid/8601.mp4",
                        teachingGuides: [
                          {
                            sentences: [
                              "Can you make bubbles with your hands?",
                              "What do we need?",
                              "영상. 활동",
                              "Do we need (bubble soap, an apron, a bowl?",
                              "We need bubble soap, an apron, and a bowl.",
                              "Rub your hands together.",
                            ],
                            sentences: [
                              "Touch the bubbles with your hands!",
                              "Circle the things we need.",
                              "bubble soap, an apron, and a bowl.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "fishy fishy in a brook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-2",
                        color: "green",
                        imageURL: "",
                        videoTitle: "none",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-03.jpg?alt=media&token=07e67d18-3dc0-4080-8df1-0610797f751b",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-03%20lets%20make.mp4?alt=media&token=193db6f3-d111-421e-ba76-fc580bf6f663",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-03.png?alt=media&token=3d2af9fb-9dfc-48c2-a022-811fe9315e2d",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book3/k1_v3_howTo.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book3/k1_v3_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-03.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book3/k1_v3_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-03.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-03%20teacher%20supplement.jpg?alt=media&token=c8f7a375-624d-417f-aefe-3220217af1c9",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-03%20teacher%20supplement.pdf?alt=media&token=e0202176-a4d4-40cc-b659-c43c4224a313",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-03%20worksheets.jpg?alt=media&token=7afed978-d0d6-412b-87d5-e516dc4b2c2c",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-03%20worksheets.pdf?alt=media&token=85024068-e7d0-4b5c-8dd2-2ec2825f17b3",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "fishy fishy in a brook story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "fishy fishy in a brook song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "wash my hands",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "open shut them",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "happy family",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "finger family",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "months song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "big E little e(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "big F little f(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Alphabet Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "the alphabet song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k1-04
          {
            volume: "04",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-04-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What shape is this?",
                              "Square / Circle / Triangle.",
                              "Let's sing with motions.",
                              "Square, square, square!",
                              "Circle, circle, circle!",
                              "Triangle, triangle, triangle!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "three little monkeys",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "What's the weather like today?",
                              "Look out the window.",
                            ],
                            sentences: [
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Jenny Story",
                              "Let's sing with motions.",
                              "hop, market, milk",
                              "Put the market and hop stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the picture(k1-04)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see?",
                              "영상, 상호작용",
                              "Here's a market.",
                              "I see many people./Jenny./fruits,/vegetables,",
                              "/cheese and milk /a big milk can.",
                              "Do you see (Jenny/milk/a market/a milk can)?",
                              "Yes/no. I see Jenny, milk, a market, and a milk can.",
                              "Circle the pictures.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                          {
                            sentences: [
                              "Do you see (fruit/ cheese/ vegetables /milk)",
                              "What do you see at a market?",
                              "fruits / vegetables / cheese and milk / a big milk can",
                              "Draw a picture.",
                              "I see (fruit/ cheese/ vegetables /milk)",
                              "Excellent!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_02.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big F little f(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big F Little f.",
                              "What letter is this?",
                              "Big F and little f.",
                              "fish",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Jenny Story",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets act it out(k1-04)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "This is a (market / school / hospital / home).",
                              "This is Jenny.",
                              "Can you act like Jenny?",
                              "영상. 상호작용",
                            ],
                          },
                          {
                            sentences: [
                              "Where is she going?",
                              "Let's act like Jenny.",
                              "hurry to the market",
                              "walk to school",
                              "run to the hospital",
                              "hop home",
                              "Good Job",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_151.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "my town",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-04-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "The Alphabet song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Alphabet Song.",
                              "영상의 Alphabet을 point하며,",
                            ],
                          },
                          {
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Jenny Story 영상.",
                              "Let's sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big G little g(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big G little g.",
                              "Letter G (‘그' )",
                              "What letter is this?",
                              "Big G and little g.",
                            ],
                          },
                          {
                            sentences: [
                              "Trace the Big G and little g.",
                              "Put the matching sticker on the circle.",
                              "Trace the little g in the word grapes.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "three little monkeys",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big G little g",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen and find the letters. G g",
                              "Point to the big G and little g.",
                              "Say the sound of the letter G.",
                              "Find the letters big G and little g.",
                              "Circle the letters big G and little g.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_13.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Jenny Story",
                              "Let's sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "lets count(k1-04)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: ["Let's Count 4 and 5"],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book4/prek_v4_count.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "How do you feel?",
                              "happy / sad / angry",
                              "Are you happy?",
                              "I am happy",
                              "Circle the picture.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Jenny Story",
                              "Let's sing with motions.",
                              "Good job singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "hop hop hop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Hop, Hop, Hop",
                              "This is Jenny.",
                              "Jenny is hopping to the market.",
                              "How many hops do you see?",
                              "I see 4 hops.",
                              "영상. 상호작용",
                            ],
                            sentences: [
                              "How many hops?",
                              "Let's count.",
                              "One, two, three. Four",
                              "I see 4 hops.",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_18.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "open shut them",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-04-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "whose hat is it",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Whose Hat Is It?",
                              "Can you guess whose hat it is?",
                              "영상, 상호작용",
                              "There are shadow hats.",
                              "They are black. / Whose hat is it?",
                              "Can you find it?",
                              "firefighter / baker / police officer",
                              "Put the matching stickers on the circles.",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_16.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing ",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hats",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book4/prek_v4_activity1.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "The Weather Song",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "Good job!",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "who has the milk(Stella)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Who Has the Milk?",
                              "Let's play a game.",
                              "영상, 활동",
                              "Jenny has to guess who has the milk.",
                              "Play rock, paper, and scissors",
                              "Who has the milk?",
                              "How to play:",
                              "Play rock, paper, and scissors",
                              "Who has the milk?",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/k1v4_vid2.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big G little g(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big G little g.",
                              "Letter G (‘그')",
                              "What letter is this?",
                              "Big G and little g.",
                              "grapes",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big H little h(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big H little h.",
                              "Letter H (‘흐' )",
                              "What letter is this?",
                              "Big H and little h.",
                            ],
                            sentences: [
                              "Trace the Big H and little h.",
                              "Put the matching sticker on the circle.",
                              "Trace the little h in the word ham.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big H little h",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen and find the letters. H, h",
                              "Find the letters big H and little h. 영상.",
                              "Point to the big H and little h.",
                              "Say the sound of the letter H.",
                              "Find the letters big H and little h.",
                              "Circle the letters big H and little h.",
                              "Good Job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_17.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-04-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "to the market",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "To the Market",
                              "Let's buy some milk.",
                              "Draw a line to the market",
                              "What Do You See?",
                              "Home, Fire Station, Bakery, School, Hospital, Market",
                              "Right , Left , Up , Down",
                              "영상, 상호작용",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                            sentences: [
                              "Right / Left / Up / Down",
                              "Home / Fire Station / Bakery / School / Market",
                              "Draw a line to the market.",
                              "Good job!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/activity/jennyPage.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "my town",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "how to make milkshake",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Milk / Ice cream / Orange/ Grape / Strawberry / Banana",
                              "영상. 상호작용",
                              "Put the stickers in the blender.",
                              "Good job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji, Come up here",
                              "Good job! Hight five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/k1v4_vid1.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing  ",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "green",
                        imageURL: "",
                        videoTitle: "open shut them",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "three little monkeys",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "Jenny story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "시장에 서둘러 가요,",
                              "깡충, 깡충, 깡충.",
                              "우유를 흘렸어요.",
                              "한 방울, 한 방울, 한 방울,",
                              "모든 방울, 모든 방울,",
                              "제니는 빈 통으로 집에 왔네요.",
                            ],
                          },
                        ],
                      },

                      // 3rd round
                      {
                        name: "Activity-14",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "mirror mirror(Stella)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Mirror. Mirror",
                              "This is a mirror",
                              "What do you see?",
                              "영상, 상호작용",
                              "Draw a dot on your face.",
                              "Where is the dot in the mirror?",
                              "Point to the dot in the mirror.",
                              "Do you see a (happy/sad) face?",
                            ],
                            sentences: [
                              "Let's look at the mirror.",
                              "Can you see the dot?",
                              "Draw your face with the dot.",
                              "Good job!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/k1v4_vid5.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "Jenny song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "green",
                        imageURL: "",
                        videoTitle: "",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-04.jpg?alt=media&token=a425d422-c8f8-449f-bd8a-22c32bc65abb",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-04%20lets%20make.mp4?alt=media&token=990e898c-dfe2-4cc1-9cee-080b066ff831",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-04.png?alt=media&token=ef6616ff-00eb-4309-bc7c-841c86c9786a",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k1/book4/K1_v4.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book4/k1_v4_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-04.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book4/k1_v4_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-04.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-04%20teacher%20supplement.jpg?alt=media&token=c5f939db-200f-4e5b-a849-f1abb00f1e05",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-04%20teacher%20supplement.pdf?alt=media&token=f82879b1-e8d8-4216-9e02-bb0447549a7c",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-04%20worksheets.jpg?alt=media&token=7796d66a-fc20-4ae1-a745-7744c9718820",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-04%20worksheets.pdf?alt=media&token=0e60a3cb-c483-475c-9358-c273a9fe38cd",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "Jenny story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },

              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "Jenny song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine",
                color: "cyan",
                imageURL: "",
                videoTitle: "brush my teeth",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "one little finger",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "my town",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "three little monkeys",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "shapes song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-4",
                color: "orange",
                imageURL: "",
                videoTitle: "the weather song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "pink",
                imageURL: "",
                videoTitle: "big G little g(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "big H little h(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "ABC Song",
                color: "green",
                imageURL: "",
                videoTitle: "ABC song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k1-05
          {
            volume: "05",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-05-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What colors are they?",
                              "Red / Blue / Yellow",
                              "Colors Song",
                            ],
                            sentences: [
                              "Let's sing with motions.",
                              "The color red! Red! Red!",
                              "The color blue! Blue! Blue!",
                              "The color yellow! Yellow! Yellow!",
                              "This is the iWonder rainbow!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "fruit trees",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book5/prek_v5_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "thank you song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "What's the weather like today?",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "one, two, three, four, five, six, seven, potato",
                              "Put the stickers on the circles.",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k1-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see?",
                              "영상, 상호작용.",
                              "These are potatoes.",
                              "They are holding food.",
                              "Do you see",
                              "(potatoes/a measuring cup/salt/mashed potatoes)?",
                              "Yes, I see (the potatoes/mashed potatoes).",
                              "Circle the pictures.",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                            sentences: [
                              "What kind of potatoes do you want?",
                              "Do you want",
                              "(mashed potatoes / french fries / a baked potato)?",
                              "Draw a picture.",
                              "I want (mashed potatoes).",
                              "Excellent!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_36.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i like to eat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big H little h(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big H Little h.",
                              "Letter H (‘흐')",
                              "What letter is this?",
                              "Big H and little h.",
                              "ham",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "one, two, three, four, five, six, seven, potato",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "good foods",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What foods are good for us?",
                              "영상, 상호작용.",
                              "What foods are good for us?",
                              "Is (a lollipop / rice / a cookie / fish / an apple /",
                              "a doughnut / a potato / chocolate / a carrot)",
                            ],
                            sentences: [
                              "Good for us?",
                              "Rice, fish, apples, potatoes, and",
                              "carrots are good for us.",
                              "It's good for me!",
                              "Circle the good foods.",
                              "Good Job !",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_37.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-05-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-9",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What shape is this?",
                              "Square / Circle / Triangle",
                              "Let's sing with motions.",
                              "Square, square, square!",
                              "Circle, circle, circle!",
                              "Triangle, triangle, triangle!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "one, two, three, four, five, six, seven, potato",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-9",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "who is doing a good job",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the friends doing a good job.",
                              "영상, 상호작용.",
                              "Find the friends doing a good job.",
                              "Sandy is eating a lollipop.",
                              "John is brushing his teeth.",
                              "John is washing his hands.",
                              "Jane is doing jumping ropes.",
                            ],
                            sentences: [
                              "Nick is drinking milk.",
                              "John has dirty hands.",
                              "Is he/she doing a good job?",
                              "Put the good job stickers on her/him.",
                              "Good job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_38.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "before and after",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big I little i(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big I little i.",
                              "Letter I (‘이' )",
                              "What letter is this?",
                              "Big I and little i.",
                              "Trace the Big I and little i.",
                              "Put the matching sticker on the circle.",
                              "Trace the little i in the word igloo.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "one, two, three, four, five, six, seven, potato",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big I little i",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen and find the letters",
                              "Find the letters big I and little i.",
                              "Point to the big I and little i.",
                              "Say the sound of the letter i.",
                              "Find the letters big I and little i.",
                              "Circle the letters big I and little i.",
                              "Good Job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_39.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Alphabet Song.",
                              "영상의 Alphabet을 point하며,",
                            ],
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN",
                              "OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "How do you feel?",
                              "happy / sad / angry",
                              "Are you happy?",
                              "I am happy",
                              "Circle the picture.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "one, two, three, four, five, six, seven, potato",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "count the vegetables",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "There are",
                              "Potatoes /tomatoes / peppers / carrots",
                              "How many (potatoes) are there?",
                              "Let's count.",
                            ],
                            sentences: [
                              "How many (potatoes) are there?",
                              "Let's count.",
                              "One, two, three. Four, Five",
                              "There are four potatoes.",
                              "There are three tomatoes.",
                              "There are four peppers.",
                              "There are five carrots.",
                              "Trace the numbers.",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_40.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Yummy Fruit",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-05-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "It is windy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big vegetables",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Which one is big?",
                              "Circle the big vegetables.",
                              "영상. 상호작용",
                              "Which one is big?",
                              "Circle the big vegetables.",
                              "big, carrot, potato, tomato",
                              "Draw a big potato.",
                              "Good job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book5/k1_v5_activity.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i like to eat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "speed number game",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's look at the cards. / What do you see?",
                              "영상. 상호작용",
                              "Let's play the Speed Number Game.",
                              "One, two, three. Four, Five",
                              "Listen and get it!",
                              "How to play: / 활동 책 참조",
                              "Find one apple!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_153.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big I little i(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big I little i.",
                              "Letter I (‘이' )",
                              "What letter is this?",
                              "Big I and little i.",
                              "igloo",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "thank you song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big J little j(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big J little j.",
                              "Letter J (‘즈' )",
                              "What letter is this?",
                              "Big J and little j.",
                            ],
                            sentences: [
                              "Trace the Big J and little j.",
                              "Put the matching stickers on the circles.",
                              "Trace the little j in the word Jam.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big J little j",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big J and little j",
                              "Point to the big J and little j.",
                              "Say the sound of the letter J.",
                              "Find the letters big J and little j.",
                              "Circle the letters big J and little j.",
                              "Good Job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_42.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "before and after",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-05-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "what do you see(k1-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you see soft or hard food?",
                              "영상. 상호작용",
                              "potato, tomato, banana, cake, ice cream, carrot",
                              "Find and color the hard food.",
                              "Good job",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_276.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "before and after",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hot potato game",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's play a game.",
                              "Song: One Potato Two Potato",
                              "Let's play a game.",
                              "Talk about how to play the game.",
                              "How to play 활동 참조",
                              "Good job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_154.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "green",
                        imageURL: "",
                        videoTitle: "lets count(k1-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book5/prek_v5_activity1.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "i like to eat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one potato two potato story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "감자 다섯, 감자 여섯,",
                              "감자 일곱, 그리고 더요.",
                              "감자 하나, 감자 둘,",
                              "감자 셋, 감자 넷,",
                              "나는 으깬 감자가 좋아요!",
                              "더 주세요, 제발!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "patterns(k1-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What comes next?",
                              "영상,  상호작용",
                              "What comes next?",
                              "potato, doughnut/ apple, tomato / banana, cone /",
                              "cucumber, pepper",
                              "Put the correct stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_41.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "one potato two potato song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "green",
                        imageURL: "",
                        videoTitle: "potato sprouts(Stella)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-05.jpg?alt=media&token=b42be672-e1e0-4172-acd1-e146b180545c",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-05%20lets%20make.mp4?alt=media&token=71449f13-5e21-475e-8096-f000552002b8",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-05.png?alt=media&token=111c751a-24a5-460d-ba92-49771fdfaf85",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k1/book5/K1_v5.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book5/k1_v5_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-05.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book5/k1_v5_assessmentA.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-05.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-05%20teacher%20supplement.jpg?alt=media&token=42837a7c-343d-455e-b398-c85d34a94147",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-05%20teacher%20supplement.pdf?alt=media&token=99802956-71ef-4f53-84a7-543edf82c498",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-05%20worksheets.jpg?alt=media&token=37afc17d-e8af-47bb-b78c-a072609f697d",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-05%20worksheets.pdf?alt=media&token=2ed6630f-10c4-4afa-8fdd-9ca2be4c623b",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "one potato two potato story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "one potato two potato song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "before and after",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "thank you song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "yummy fruits",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "i like to eat",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "colors song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "big I little i(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "big J little j(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "the alphabet song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k1-06
          {
            volume: "06",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-06-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "thank you song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "cake and songpyeon",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book6/prek_v6_activity1.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "What's the weather like today?",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                              "Put the matching sticks on the circles.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k1-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see?",
                              "영상, 상호작용",
                              "This is a farm.",
                              "There are animals.",
                              "Do you see a",
                              "(cat/turkey/rabbit/dog)?",
                              "Yes/no. I see a turkey.",
                              "Find the animal.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                          {
                            sentences: [
                              "Can you see feathers on the turkey?",
                              "Turkeys have feathers.",
                              "Draw feathers on the turkey.",
                              "Excellent!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_58.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big J little j(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big J Little j",
                              "Letter J (‘즈')",
                              "What letter is this?",
                              "Big J and little j.",
                              "jam",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "move your body",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's act like Funny Turkey.",
                              "영상, 활동",
                              "Funny Turkey wobbles his head.",
                              "He says gobble.",
                              "Can you wobble your head?",
                              "Can you say gobble?",
                              "Wobble your head!",
                              "Good Job !",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_59.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-06-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-9",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What colors are they?",
                              "Red / Blue / Yellow",
                            ],
                          },
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "The color red! Red! Red!",
                              "The color blue! Blue! Blue!",
                              "The color yellow! Yellow! Yellow!",
                              "This is the iWonder rainbow!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-9",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "say hello",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Say 'Hello', to each other.",
                              "영상, 상호작용",
                              "Say 'Hello', to each other.",
                              "Say 'Hello', in",
                              "(Chinese, Japanese, Korean, English, French).",
                              "Ni hao! / Konnichiwa!",
                              "Ahn nyoung! / Hello! / Bonjour!",
                              "Put the sticker on the circle.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_60.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big K little k(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big K little k.",
                              "Letter K (‘크' )",
                              "What letter is this?",
                              "Big K and little k.",
                              "Trace the little k in the word kiwi.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big K little k",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big K and little k.",
                              "Point to the big K and little k.",
                              "Say the sound of the letter K.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_61.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "fall foods",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How many do you see?",
                              "pears /apples / peanuts / chestnuts",
                              "These are foods you can see in the fall.",
                              "영상",
                              "How many (pears) do you see?",
                              "Let's count. / One, two, three. Four, Five, six",
                              "There are (four) (pears).",
                              "There are three apples.",
                              "There are five peanuts.",
                              "There are six chestnuts.",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_65.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "How do you feel?",
                              "happy / sad / angry",
                              "Are you happy?",
                              "I am happy.",
                              "Circle the picture.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-06-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big K little k(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big K little k.",
                              "Letter K ('크' )",
                              "What letter is this?",
                              "Big K and little k.",
                              "kiwi",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big L little l(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big L little l.",
                              "Letter L ('르' )",
                              "What letter is this?",
                              "Big L and little l.",
                              "Trace the little l in the word lemon.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big L little l",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Point to the big L and little l.",
                              "Say the sound of the letter L.",
                              "Find the letters big L and little l.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_62.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets make a chicken sandwich",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's Make Chicken Sandwich",
                              "What is in the sandwich?",
                              "Cut the sandwich in half.",
                              "Look inside!",
                              "What is in the sandwich?",
                              "Find the foods that you see inside.",
                              "Tomato, cheese, 김치, salad, banana, Chicken",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "thank you song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "It is windy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "patterns(k1-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "These are hanboks.",
                              "Say the colors in the pattern.",
                              "What comes next?",
                              "영상",
                              "Say the colors in the pattern.",
                              "What comes next?",
                              "Can you see patterns on the hanboks?",
                              "Yes, I see patterns.",
                              "Blue / Yellow / Green / Red",
                              "Color in the boxes.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book6/k1_v6_activity.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-06-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big L little l(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big L little l. 영상",
                              "Letter L ('르' )",
                              "What letter is this?",
                              "Big L and little l.",
                              "lemon",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "big M little m(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big M little m. 영상",
                              "Letter M ('므' )",
                              "What letter is this?",
                              "Big M and little m.",
                              "Trace the little m in the word milk.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN",
                              "OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big M little m",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big M and little m.",
                              "Point to the big M and little m.",
                              "Say the sound of the letter M.",
                              "Find the letters big M and little m.",
                            ],
                          },
                          {
                            sentences: [
                              "Let's find the first letter of each word.",
                              "Kiwi/Lemon/Milk) starts with (k/l/m).",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_63.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "same or different(k1-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What is different ?",
                              "영상",
                              "What is different?",
                              "Circle what is different.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_64.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "funny turkey story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "칠면조는 재미있는 새랍니다.",
                              "머리를 이리저리 앞뒤로 흔들죠.",
                              "그가 할 줄 아는 말은 단 하나,",
                              "고블, 고블, 고블!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k1-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Today is Funny Turkey's wedding day.",
                              "영상",
                              "ice cream / milk / yogurt / cheese/ camera / wedding cake",
                              "Find the hidden things.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book6/k1_v6_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "funny turkey song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "bird, funny, gobble, turkey, wobble",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "green",
                        imageURL: "",
                        videoTitle: "memory game(k1-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Look, they are the same!",
                              "영상",
                              "Let's look at the cards.",
                              "Can you find the same cards?",
                              "Look, they are the same!",
                              "cat, chicken, dog, rabbit, turkey",
                            ],
                            sentences: [
                              "Let's play a memory game.",
                              "Guess which cards are the same.",
                              "How to play 교재 참조",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "thank you song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-06.jpg?alt=media&token=d9cff864-2bc9-4281-af6a-f280d1e82283",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-06%20lets%20make.mp4?alt=media&token=eed08778-d2f7-487f-8aa8-cddce80eab8e",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-06.png?alt=media&token=5a64d08c-f466-45da-aa4f-217fc47ac7a5",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k1/book6/K1_v6.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book6/k1_v6_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-06.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book6/k1_v6_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-06.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-06%20teacher%20supplement.jpg?alt=media&token=ec86c209-fa83-42f2-ac63-1214e086ac84",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-06%20teacher%20supplement.pdf?alt=media&token=f7f7829e-9081-46dd-acc7-f8b8789125f7",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-06%20worksheets.jpg?alt=media&token=d6657cc1-1775-422a-99c3-360a09245632",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-06%20worksheets.pdf?alt=media&token=6c1d6f1d-511b-4cab-ab92-fa2ab48aad42",
                },
                {
                  title: "Daily Routine Manual",
                  color: "grey lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 1 Manual",
                  color: "lime lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 2 Manual",
                  color: "teal lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "funny turkey story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "funny turkey song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "amber",
                imageURL: "",
                videoTitle: "sorry excuse me",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "happy birthday",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "special days",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "mr Geneus",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-4",
                color: "orange",
                imageURL: "",
                videoTitle: "the weather song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "pink",
                imageURL: "",
                videoTitle: "big K little k(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "amber",
                imageURL: "",
                videoTitle: "big L little l(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "green",
                imageURL: "",
                videoTitle: "big M little m(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "ABC Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "ABC song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k1-07
          {
            volume: "07",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-07-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Can you count the numbers?",
                              "Let's sing with motions.",
                              "1, 2, 3, 4, 5. 6, 7, 8, 9, 10.",
                              "One, Two, Three, Four, Five,",
                              "Six, Seven, Eight, Nine, Ten",
                              "Let's sing the Numbers Song!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Dance for Your Daddy Song",
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "yellow and green",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book7/prek_v7_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "cross the street",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "What's the weather like today?",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "It is windy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the picture(k1-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see in the sea?",
                              "영상, 상호작용",
                              "This is the sea.",
                              "A mom, a girl and a boy are dancing.",
                              "Do you see a",
                              "(bike/truck/boat/car)?",
                              "Yes/no. I see a boat.",
                              "Find the Picture.",
                            ],
                          },
                          {
                            sentences: [
                              "Do you see a(boat/fish/seashell/starfish) in the sea?",
                              "I see a boat.",
                              "Draw some boats.",
                              "Excellent!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_72.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big M little m(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big M little m.",
                              "Letter M ('므' )",
                              "What letter is this?",
                              "Big M and little m.",
                              "milk",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "daddy is coming!",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "영상",
                              "Is Daddy coming by (car/motorcycle/tractor/boat)?",
                              "Yes/No. Daddy is coming home by boat.",
                              "Find the picture.",
                              "Good Job !",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_73.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "grandmas house",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-07-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-9",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "thank you song 2",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's all say, thank you.",
                              "Teacher / friends",
                              "Let's sing: Thank You Song.",
                              "Let's all say, thank you.",
                              "we thank you very much.",
                              "Thank you, to teacher!",
                              "Thank you, to friends!",
                              "Goodbye~",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "go and stop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big N little n(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Big N Little n",
                              "Letter N ('느' )",
                              "What letter is this?",
                              "Big N and little n.",
                              "Trace the little n in the word nuts.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big N little n",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big N and little n.",
                              "Point to the big N and little n.",
                              "Say the sound of the letter N.",
                              "Find the letters big N and little n.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_74.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "How do you feel?",
                              "happy / sad / angry",
                              "Are you happy?",
                              "I am happy",
                              "Circle the picture.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "boats and shapes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What shapes do you see?",
                              "Find the (circles/triangles/rectangles) in the boat",
                              "영상",
                              "These are boats.",
                              "How many (circles) do you see in the boat?",
                              "I see (three) (circles).",
                              "What shapes do you see?",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_76.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-07-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big N little n(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big N little n.",
                              "Letter N ('느' )",
                              "What letter is this?",
                              "Big N and little n.",
                              "nuts",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big O little o(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big O little o.",
                              "Letter O (‘어' )",
                              "What letter is this?",
                              "Big O and little o.",
                              "Trace the little o in the word orange.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN",
                              "OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big O little o",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big O and little o",
                              "Point to the big O and little o.",
                              "Say the sound of the letter O.",
                              "Find the letters big O and little o.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_75.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                              "Dance for Your Daddy Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "guessing game",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Can you find the shapes?",
                              "blue, circle, red, square, triangle, yellow",
                              "Make your own transportation with the shapes.",
                              "What did you make?",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_83.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Numbers Song.",
                              "Can you count the numbers?",
                              "Let's sing with motions.",
                              "1, 2, 3, 4, 5. 6, 7, 8, 9, 10.",
                              "One, Two, Three, Four, Five,",
                              "Six, Seven, Eight, Nine, Ten",
                              "Let's sing the Numbers Song!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                              "Dance for Your Daddy Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "same pictures",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Can you find the same pictures?",
                              "The (tractors/cars/trucks/boats) are the same.",
                              "영상",
                              "Can you find the same pictures?",
                              "The (tractors/cars/trucks/boats) are the same.",
                              "Let's find the same pictures.",
                              "Circle the same pictures.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book7/k1_v7_activity.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "grandmas house",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-07-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big O little o(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big O little o.",
                              "Letter O ('어' )",
                              "orange.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                              "Dance for Your Daddy Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "big P little p(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Big P Little p",
                              "Let's sing Big P little p.",
                              "Letter P ('프' )",
                              "What letter is this?",
                              "Big P and little p.",
                              "Trace the little p in the word pizza.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "cross the street",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big P little p",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big P and little p.",
                              "Say the sound of the letter P",
                              "Point to the big P and little p.",
                              "Find the letters big P and little p.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                          {
                            sentences: [
                              "Make the Words.",
                              "Let's find the first letter of each word.",
                              "(Nuts/Orange/Pizza) starts with (n/o/p).",
                              "Put the matching stickers on the circles.",
                              "Good job! Hight Five!!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_84.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                              "Dance for Your Daddy Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "cross the street 2",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Robby wants to cross the street.",
                              "Let's move Robby.",
                              "Left / Right",
                              "Put the sticker on the circle.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_85.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "go and stop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "dance for your daddy story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀가 아빠를 위해 춤을 춰요.",
                              "소년이 아빠를 위해 춤을 춰요.",
                              "물고기를 위해 춤을 춰요.",
                              "배가 오면 가족과 함께 생선을 먹을 거예요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k1-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "We are going to Grandma's house.",
                              "apple/ grapes / orange / bananas / boat / bicycle",
                              "Find the hidden things.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book7/k1_v7_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "dance for your daddy song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boat, daddy, fishy, lady, man",
                              "Dance for Your Daddy Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "green",
                        imageURL: "",
                        videoTitle: "watch the wheels",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-07.jpg?alt=media&token=a282f8a7-9d35-49d8-a3a2-8a01ae177f37",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-07%20lets%20make.mp4?alt=media&token=f2344022-2d03-46a2-9bec-e5af732b1c15",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-07.png?alt=media&token=8c04c2fc-da57-4485-836f-29daae3cee89",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k1/book7/K1_v7.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book7/k1_v7_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-07.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book7/k1_v7_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-07.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-07%20teacher%20supplement.jpg?alt=media&token=656fdecb-f19b-46b8-b3ee-43b7e5012239",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-07%20teacher%20supplement.pdf?alt=media&token=5f4c19e8-e04f-41e0-aad7-381f9be8a9b9",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-07%20worksheets.jpg?alt=media&token=a32e47c4-1bc3-4607-887a-526a03895f1a",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-07%20worksheets.pdf?alt=media&token=f48823e2-8fc4-481b-a8d4-3457c9c662e8",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "dance for your daddy story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "dance for your daddy song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "cross the street",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "go and stop",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "grandmas house",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "the wheels on the bus",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-13",
                color: "orange",
                imageURL: "",
                videoTitle: "numbers song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "big N little n(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "amber",
                imageURL: "",
                videoTitle: "big O little o(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "green",
                imageURL: "",
                videoTitle: "big P little p(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k1-08
          {
            volume: "08",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-08-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "stretching song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Move your body while you are singing.",
                              "Let's sing with motions.",
                              "Stretch your arms up and down.",
                              "Up, up, up, up, up!",
                              "Down, down, down, down, down!",
                            ],
                          },
                          {
                            sentences: [
                              "Move your body while you are singing.",
                              "Right, right, right, right!",
                              "Left, left, left, left!",
                              "Stretch your neck round and round.",
                              "Round and round and round!",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "circles",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book8/prek_v8_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "use the potty",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: ["Let's sing with motions."],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "It is windy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k1-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see?",
                              "영상, 상호작용",
                              "This is Cobbler.",
                              "He fixes shoes.",
                              "There are some tools and shoes in the picture.",
                              "Do you see",
                              "(a hammer/shoes/popcorn/Cobbler)?",
                              "Yes/no. I see (a hammer/shoes/Cobbler).",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                          {
                            sentences: [
                              "My shoes are ( blue / red / brown / pink ).",
                              "What color are your shoes?",
                              "Show your shoes to your friends.",
                              "Draw your shoes.",
                              "Excellent!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_99.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big P little p(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big P Little p.",
                              "Letter P (‘프' )",
                              "What letter is this? / Big P and little p.",
                              "pizza",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "cobblers workshop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What is Cobbler fixing in the story?",
                              "Is Cobbler fixing",
                              "(a clock/a shoe/an umbrella/a camera)?",
                              "Yes/No. Cobbler is fixing a shoe.",
                              "Find the picture.",
                              "Good Job !",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_102.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the clock",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-08-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-9",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Can you count the numbers?",
                              "Let's sing with motions.",
                              "1, 2, 3, 4, 5. 6, 7, 8, 9, 10.",
                              "One, Two, Three, Four, Five,",
                              "Let's sing the Numbers Song!",
                              "Six, Seven, Eight, Nine, Ten",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-9",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "missing tools",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you need?",
                              "I need a (toothbrush/brush/fork/knife).",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_103.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big Q little q(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Letter Q ('크')",
                              "What letter is this?",
                              "Big Q and little q.",
                              "Trace the little q in the word queen.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "this is the way",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big Q little q",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Point to the big Q and little q.",
                              "Say the sound of the letter Q.",
                              "Find the letters big Q and little q.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_104.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "same or different(k1-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What is (the same/different)?",
                              "Find the (same/different) things in the picture.",
                              "The (popcorn machine/oven/paint roller) is missing.",
                              "Circle the three different things.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book8/k1_v8_activity1.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "happy / sad / angry",
                              "Are you happy?",
                              "I am happy",
                              "Circle the picture.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-08-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "stretching song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Move your body while you are singing.",
                              "Right, right, right, right!",
                              "Left, left, left, left!",
                              "Stretch your neck round and round.",
                              "Round and round and round!",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Stretch your arms up and down.",
                              "Up, up, up, up, up!",
                              "Down, down, down, down, down!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "tools in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's find the tools in the kitchen.",
                              "this is a (brush, computer, fork, frying pan,",
                              "mobile phone, shovel, toaster, toothbrush , TV)",
                              "what do we use in the kitchen?",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_106.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big Q little q(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big Q little q.",
                              "Letter Q ('크' )",
                              "What letter is this?",
                              "Big Q and little q.",
                              "queen",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big R little r(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big R little r.",
                              "Letter R (‘으르' )",
                              "What letter is this?",
                              "Big R and little r.",
                              "Trace the little r in the word rice.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN",
                              "OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big R little r",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big R and little r. 영상",
                              "Point to the big R and little r.",
                              "Say the sound of the letter R.",
                              "Find the letters big R and little r.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_105.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i spy",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's look at the two cards.",
                              "Can you find the same picture?",
                              "brush, clock, fork, frying pan,",
                              "glasses, hair dryer, hammer",
                              "knife, phone, robot, spoon, toaster, toothbrush",
                              "Let's play I Spy.",
                              "Find the same picture. (How to play: 참조)",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_155.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "this is the way",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-08-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big R little r(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big R little r.",
                              "Letter R (‘으르' )",
                              "What letter is this?",
                              "Big R and little r.",
                              "rice",
                              "Good Job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "big S little s(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Letter S (‘스' )",
                              "What letter is this?",
                              "Big S and little s.",
                              "Trace the little s in the word spoon.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "this is the way",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big S little s",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big S and little s.",
                              "Point to the big S and little s.",
                              "Say the sound of the letter S.",
                              "Find the letters big S and little s.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                          {
                            sentences: [
                              "Make the Words.",
                              "Let's find the first letter of each word.",
                              "(Queen/Rice/Spoon) starts with (q/r/s).",
                              "Put the matching stickers on the circles.",
                              "Good Job!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_100.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "what is it",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Listen and find the answer.",
                              "It's round.",
                              "It says 'tick, tock!',",
                              "It is a … clock.",
                              "Put the matching sticker on the circle.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_101.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the clock",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "cobbler cobbler story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                              "위로 꿰매고, 아래로 꿰매주세요.",
                              "그러면 나는 마을을 걸어 다닐 수 있어요.",
                              "만약 제 신발을 고쳐주신다면,",
                              "제가 팝콘을 드릴게요!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k1-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "This is Cobbler's workshop. Look around!",
                              "broccoli/ carrot / corn / tomato",
                              "glasses / hammer / robot / shovel",
                              "Find the hidden things.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book8/k1_v8_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "cobbler cobbler song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "fix, popcorn, shoe",
                              "fix, popcorn, shoe",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "green",
                        imageURL: "",
                        videoTitle: "using kitchen tools",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-08.jpg?alt=media&token=7985815e-8766-422b-9d99-3f45b866e4e3",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-08%20lets%20make.mp4?alt=media&token=4dfe9f0f-6a27-4a4a-91a9-e7f83423a00d",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-08.png?alt=media&token=ad4588fe-72d0-4b35-a40a-a4c02fe5dd2f",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k1/book8/K1_v8.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book8/k1_v8_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-08.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book8/k1_v8_assessmentB.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-08.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-08%20teacher%20supplement.jpg?alt=media&token=fcfd5fc2-4a17-400d-91d3-ba3b690a04a1",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-08%20teacher%20supplement.pdf?alt=media&token=7f0f5036-5909-4f43-a869-b9c9be3010f7",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-08%20worksheets.jpg?alt=media&token=95014def-d8b9-4365-ba57-802694e33c41",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-08%20worksheets.pdf?alt=media&token=86672dca-cd98-46a9-86c3-6bcd950dfec1",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "cobbler cobbler story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "cobbler cobbler song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "use the potty",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "amber",
                videoTitle: "the clock",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "in the kitchen",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "this is the way",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "stretching song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "pink",
                imageURL: "",
                videoTitle: "big Q little q(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "amber",
                imageURL: "",
                videoTitle: "big R little r(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "green",
                imageURL: "",
                videoTitle: "big S little s(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k1-09
          {
            volume: "09",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-09-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "seasons song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Pretend to be a tree while you are singing.",
                              "Let's sing with motions.",
                              "Spring, Summer, Fall, Winter",
                              "Spring, Summer, Fall, Winter",
                              "These are the four seasons.",
                              "Spring, Summer, Fall, Winter",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "triangles",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book9/prek_v9_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "pat pat pat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "It is windy.",
                              "It is snowy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k1-09)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see in the sea?",
                              "영상, 상호작용",
                              "It's Christmas!",
                              "This is Jack Horner.",
                              "Do you see",
                              "(a Christmas tree/a pie/Jack/Santa)?",
                              "Yes/no. I see (a Christmas tree/a pie/Jack).",
                              "Find the Pictures.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "I want a Christmas pie!",
                              "I want to eat (turkey/cookies/pie/ham).",
                              "Do you want to eat (turkey/cookies/pie/ham)?",
                              "Draw what you want to eat.",
                              "Excellent!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_120.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "rain rain go away",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big S little s(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing: Big S Little s.",
                              "Letter S (‘스' )",
                              "What letter is this?",
                              "Big S and little s.",
                              "spoon",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "be a good boy",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Jack pulled out the plum with his thumb.",
                              "영상, 활동",
                              "Is Jack a good boy?",
                              "Can Jack get a Christmas present from Santa?",
                              "Santa gives Christmas presents to good boys and girls.",
                              "Put the sticker on the circle.",
                              "Good Job !",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_121.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-09-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-9",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "stretching song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Move your body while you are singing.",
                              "Let's sing with motions.",
                              "Stretch your arms up and down.",
                              "Up, up, up, up, up!",
                              "Down, down, down, down, down!",
                              "Right, right, right, right!",
                              "Left, left, left, left!",
                              "Stretch your neck round and round.",
                              "Round and round and round!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-9",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "winter clothes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do we wear in the winter?",
                              "boots, coat, hat, mittens, pants, sandals,",
                              "scarf, shorts, skirt, sweater, t-shirt",
                              "This is a (coat)/These are (pants).",
                              "Can you find the (coat)?",
                              "Find and circle the winter clothes.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                          {
                            sentences: [
                              "What do we wear in the winter?",
                              "boots, coat, hat, mittens, pants, sandals,",
                              "scarf, shorts, skirt, sweater, t-shirt",
                              "This is a (coat)/These are (pants).",
                              "Can you find the (coat)?",
                              "Find and circle the winter clothes.",
                              "Good job!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_122.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "rain rain go away",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big T little t(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big T little t. 영상을 본다.",
                              "Letter What letter is this?",
                              "Big T and little t.",
                              "Letter T ('트', )",
                              "Trace the letter t in the word tomato.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big T little t",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big T and little t.",
                              "Point to the big T and little t.",
                              "Say the sound of the letter T.",
                              "Find the letters big T and little t.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_123.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "small pies",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Look at the pies.",
                              "영상",
                              "Circle the small pies.",
                              "Draw a small pie.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book9/k1_v9_activity3.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "How do you feel?",
                              "happy / sad / angry",
                              "Are you happy?",
                              "I am happy.",
                              "Circle the picture.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-09-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big U little u(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big U little u.",
                              "What letter is this?",
                              "Big U and little u.",
                              "Trace the little u in the word up.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big U little u",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big U and little u.",
                              "Point to the big U and little u.",
                              "Say the sound of the letter U.",
                              "Find the letters big U and little u.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_124.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN",
                              "OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "seasons song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Pretend to be a tree while you are singing.",
                              "Let's sing with motions.",
                              "Spring, Summer, Fall, Winter",
                              "Spring, Summer, Fall, Winter",
                              "These are the four seasons.",
                              "Spring, Summer, Fall, Winter",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "patterns(k1-09)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "This is a Christmas tree.",
                              "What shapes do you see in the pattern?",
                              "What comes next?",
                              "Put the matching stickers on the circles.",
                              "This is a Christmas tree.",
                              "What shapes do you see in the pattern?",
                              "I see a (circle / triangle / square).",
                              "A (circle/triangle/square) comes next.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_125.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "pat pat pat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "snowman 2",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Make a snowman.",
                              "Bring a snowman inside.",
                              "Watch the snowman.",
                              "Leave the snowman in the classroom for an hour.",
                              "What happened to the snowman?",
                              "The snowman melted.",
                              "What happened to the snowman?",
                              "Draw the snowman.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_126.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "snowman 1",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book9/prek_v9_activity1.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-09-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big V little v(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big V little v.",
                              "Letter V (‘브' )",
                              "What letter is this?",
                              "Big V and little v.",
                              "Trace the little v in the word van.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "big V little v",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big V and little v.",
                              "Point to the big V and little v.",
                              "Say the sound of the letter V.",
                              "Find the letters big V and little v.",
                              "Make the Words.",
                              "Let's find the first letter of each words.",
                              "(Tomato/Up/Van) starts with (t/u/v).",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_127.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "same or different(k1-09)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Snowflakes have 6 points.",
                              "Same or Different.",
                              "One / Two /Tree / Four / Five / Six",
                              "Find the same snowflakes and color them in.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_128.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "green",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Q Button - what month is it?",
                              "month screen - March",
                              "음표 - 'month song'",
                              "week - bar",
                              "음표 - 'week song'",
                              "(Sunday/ Monday/…….)",
                              "Next",
                              "what day is it Today?",
                              "Next - Today is……",
                              "(다른 날일 경우 Try again )",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "pat pat pat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Jack Horner story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "어린 잭 호너가 구석에 앉아서",
                              "크리스마스 파이를 먹고 있어요.",
                              "엄지손가락을 파이에 쑥 넣어",
                              "자두를 꺼내고는",
                              "'나는 참 좋은 아이야!',라고 말했죠.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k1-09)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Merry Christmas!",
                              "Let's have a party!",
                              "egg / ham / hot chocolate / turkey",
                              "boots / mittens / scarf / snowman",
                              "Find the hidden things.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book9/k1_v9_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "little Jack Horner song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "boy, Christmas pie, good, plum, put, thumb",
                              "Little Jack Horner Song",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "green",
                        imageURL: "",
                        videoTitle: "dress up game",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "rain rain go away",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-09.jpg?alt=media&token=b62f4849-293c-4192-881f-abe2a6d6aa3a",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-09%20lets%20make.mp4?alt=media&token=f544f50f-e2cf-4458-b176-01c1c5cf1362",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-09.png?alt=media&token=7f0b975d-ac2a-4ee5-a43c-46385a718a15",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k1/book9/K1_v9.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book9/k1_v9_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-09.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book9/k1_v9_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-09.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-09%20teacher%20supplement.jpg?alt=media&token=399bb48c-d63e-496b-bf3b-c84db42f977c",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-09%20teacher%20supplement.pdf?alt=media&token=9cf15366-78f0-4665-a200-6669e7e7eaa7",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-09%20worksheets.jpg?alt=media&token=b7ad922e-25e1-472c-b301-7454dc4111df",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-09%20worksheets.pdf?alt=media&token=b64ca66c-4186-44f3-8bb9-95c8a699b325",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "little Jack Horner story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },

              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "little Jack Horner song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "cover your mouth",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "merry christmas",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "pat pat pat",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "rain rain go away",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "seasons song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [
                      "Pretend to be a tree while you are singing.",
                      "Let's sing with motions.",
                      "Spring, Summer, Fall, Winter",
                      "Spring, Summer, Fall, Winter",
                      "These are the four seasons.",
                      "Spring, Summer, Fall, Winter",
                    ],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "pink",
                imageURL: "",
                videoTitle: "big T little t(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "amber",
                imageURL: "",
                videoTitle: "big U little u(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "pink",
                imageURL: "",
                videoTitle: "big V little v(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k1-10
          {
            volume: "10",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k1-10-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "friendship song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Make heart shapes using our body parts.",
                              "Let's sing with motions.",
                              "One little heart to give away.",
                              "One little heart for you today.",
                              "'I love you', is what I say.",
                              "I'll give one to my friend today.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "squares",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book10/prek_v10_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i love earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "It is sunny.",
                              "It is cloudy.",
                              "It is rainy.",
                              "It is windy.",
                              "It is snowy.",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k1-10)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see ?",
                              "영상, 상호작용",
                              "I see a girl.",
                              "She is camping.",
                              "Do you see",
                              "( trees / flowers / fish / a frog )?",
                              "Yes/no.",
                              "I see ( trees / flowers / fish / a frog ).",
                              "Find the Pictures",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                          {
                            sentences: [
                              "when you go camping?",
                              "what do you want to do",
                              "I want to (barbecue / play /go fishing / sleep in a tent).",
                              "Camping is fun!",
                              "What do you want to do?",
                              "Draw the answer.",
                              "Excellent!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_129.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the green string",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "big V little v(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Big V Little v.",
                              "Let's sing: Big V Little v.",
                              "Letter V (‘브' )",
                              "What letter is this?",
                              "Big V and little v.",
                              "van",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "reuse",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What can you make with a (bottle/box)?",
                              "Bottles and boxes! / What can you make?",
                              "Let's make a (vase/pencil holder).",
                              "Reuse the bottle! / Vase!",
                              "Reuse the box! / Pencil holder!",
                              "Put the matching stickers on the circles.",
                              "Good Job !",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "twinkle twinkle little star",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k1-10-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big W little w(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Letter W ('워' )",
                              "What letter is this?",
                              "Big W and little w.",
                              "Trace the letter w in the word waffle.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big W little w",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big W and little w.",
                              "Point to the big W and little w.",
                              "Say the sound of the letter W.",
                              "Find the letters big W and little w.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_130.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "big X little x(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big X little x.",
                              "Letter X ('스' )",
                              "What letter is this?",
                              "Big W and little x.",
                              "Trace the letter x in the word fox.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big X little x",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big X and little x. 영상",
                              "Point to the big X and little x.",
                              "Say the sound of the letter X.",
                              "Find the letters big X and little x.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_131.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "manners",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "How do you feel?",
                              "happy / sad / angry",
                              "Are you happy?",
                              "I am happy",
                              "Circle the picture.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one to five",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's count each animal.",
                              "How many",
                              "(dogs/fish/frogs/rabbits/squirrels) do you see?",
                              "I see (one) (frog).",
                              "Put the sticker on the circle.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_132.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "twinkle twinkle little star",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k1-10-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "friendship song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Make heart shapes using our body parts.",
                              "Let's sing with motions.",
                              "One little heart to give away.",
                              "One little heart for you today.",
                              "'I love you', is what I say.",
                              "I'll give one to my friend today.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "finish the shapes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "This is the (earth/tree/flower/butterfly).",
                              "What shape is this?",
                              "This is the (earth/tree/flower/butterfly).",
                              "What shape is this?",
                              "This is a (circle/heart/square/triangle).",
                              "Complete the shapes.",
                              "Let's find the shapes.",
                              "Trace and color the shapes.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book10/k1_v10_activity1.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i love earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "big Y little y(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big Y little y.",
                              "Letter Y (‘여' )",
                              "What letter is this?",
                              "Big Y and little y.",
                              "Trace the little y in the word yogurt.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-2",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big Y little y",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big Y and little y.",
                              "Point to the big Y and little y.",
                              "Say the sound of the letter Y.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_133.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the green string",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "P1-1033h1-1",
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN",
                              "OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "build the cup tower",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How many cups do you see?",
                              "Let's count and write the numbers.",
                              "count, cup, down, left, right, up",
                              "Let's build the cup tower together.",
                              "Take a picture and paste it.",
                              "Good Job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_156.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "manners",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k1-10-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "big Z little z(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing Big Z little z.",
                              "Letter Z ('즈')",
                              "What letter is this?",
                              "Big Z and little z.",
                              "Trace the little z in the word zucchini.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "green",
                        imageURL: "",
                        videoTitle: "big Z little z",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Find the letters big Z and little z.",
                              "Point to the big Z and little z.",
                              "Say the sound of the letter Z.",
                            ],
                          },
                          {
                            sentences: [
                              "Find the letters big Z and little z.",
                              "Point to the big Z and little z.",
                              "Say the sound of the letter Z.",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_134.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "manners",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets move Robby(k1-10)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Robby wants to get some food.",
                              "Robby wants to get some food.",
                              "Left / Right",
                              "Put the stickers on the circles.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_135.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "green",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Q Button - what month is it?",
                              "month screen - March",
                              "음표 - 'month song'",
                              "week - bar",
                              "음표 - 'week song'",
                              "(Sunday/ Monday/…….)",
                              "Next",
                              "what day is it Today?",
                              "Next - Today is……",
                              "(다른 날일 경우 Try again)",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "i love earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one two three four five story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "하나, 둘, 셋, 넷, 다섯,",
                              "나는 살아있는 물고기를 잡은 후,",
                              "여섯, 일곱, 여덟, 아홉, 열,",
                              "난 다시 물고기를 놓아 줬어요.",
                              "왜 물고기를 놓아줬죠?",
                              "내 손가락을 물어서요.",
                              "어느 손가락을 물었는데요?",
                              "오른쪽 새끼손가락이요.",
                            ],
                          },
                        ],
                      },

                      // 3rd round
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k1-10)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's go camping!",
                              "영상, 활동",
                              "bread / cereal / doughnut/ bin / bottle / can / juice / newspaper",
                              "Find the hidden things.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min JI, Come up here",
                              "Good job! Hight Five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book10/k1_v10_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "one two three four five song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's listen to the Story.",
                              "Let's sing with motions.",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "bite, finger, fish, five, four, one, three, two",
                              "Six, seven, eight, nine, ten",
                              "Put the matching stickers on the circles.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "green",
                        imageURL: "",
                        videoTitle: "musical kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "twinkle twinkle little star",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K1-10.jpg?alt=media&token=d7b0c497-681f-45cf-8821-6f1a079450e6",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk1-10%20lets%20make.mp4?alt=media&token=a6d29537-0e7e-4ff1-a915-6ebc88429f80",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K1-10.png?alt=media&token=f7817707-7249-42a4-a5ec-bb8854968eaa",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k1/book10/K1_v10.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book10/k1_v10_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K1-10.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book10/k1_v10_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k1-10.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-10%20teacher%20supplement.jpg?alt=media&token=205c647c-c350-494b-b411-b5d194eec71f",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-10%20teacher%20supplement.pdf?alt=media&token=17d607fd-b122-4bed-895a-82f120d0f4a7",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-10%20worksheets.jpg?alt=media&token=26aa2b09-0650-40d9-bbd2-156c92c020ff",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK1-10%20worksheets.pdf?alt=media&token=2691d9c4-2665-4a16-b135-e3b89b61d0a0",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "one two three four five story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "one two three four five song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "i love earth",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "twinkle twinkle little star",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "manners",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "friendship song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "big W little w(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "cyan",
                imageURL: "",
                videoTitle: "big X little x(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "big Y little y(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-19",
                color: "amber",
                imageURL: "",
                videoTitle: "big Z little z(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },
        ],
      },

      // level 2
      {
        level: "K2",
        color: "grey",
        books: [
          // k2-01
          {
            volume: "01",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-01-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "hello song daily routine",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Hello and Goodbye",
                              "Hello Song 영상.",
                              "Let's sing the Hello Song",
                              "Put the matching sticker on the circle.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "my face(prek)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book1/prek_v1_face.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "goodbye song daily routine",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Goodbye Song",
                              "Put the matching sticker on the circle.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out  the window. ",
                              "창문을 가리키며",
                              "It is sunny ",
                              "머리위로 두 손을 마주 잡으며",
                              "It is cloudy ",
                              "양손으로 구름모양으로 만들며",
                              "It is rainy ",
                              "양손으로  비가 내리듯 손가락을 움직이며",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k2-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see?",
                              "영상, 상호작용.",
                              "Let's look at the picture.",
                              "He has ice cream.",
                              "Do you see",
                              "(a cookie / cake / ice cream/ an apple)?",
                              "Circle the picture.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                          {
                            sentences: [
                              "Feelings ",
                              "How does he feel?",
                              "Happy  /   sad  /  angry ",
                              "Circle the picture.",
                              "How do you feel?",
                              "Are you happy?",
                              "I am happy",
                              "How do you feel?",
                              "Draw your face.",
                              "Excellent! ",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/06/06_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "I am happy !",
                              "Happy / sad / angry ",
                              "Hello, how are you?",
                              "How do you feel?",
                              "Happy, happy, I am happy.",
                              "This is how I feel.",
                              "Circle the picture",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "sound it out(k2-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Can you say scream / ice cream?",
                              "scream / ice cream",
                              "Let's say (scream/ice cream).",
                              "Let's play a game.",
                              "Raise your hand when you hear ice cream or scream.",
                              "Circle the picture.",
                              "Dog / Dinosaur / Ice cream/ Clown",
                              "Excellent!",
                            ],
                          },
                          {
                            sentences: [
                              "Look at the picture.",
                              "What are they doing?",
                              "They are screaming.",
                              "What are they screaming for?",
                              "We all scream! ",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/04/04_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "hello song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-01-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Q Button - what month is it?",
                              "month screen - March ",
                              "음표 - 'month song'",
                              "week - bar",
                              "음표 - 'week song'",
                              "(Sunday/ Monday/…….)",
                              "Next",
                              "what day is it  Today?",
                              "Next - Today is…… ",
                              "(다른 날일 경우 Try again )",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Alphabet Song.",
                              "영상의 Alphabet을 point하며,",
                            ],
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN",
                              "OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "hello friends",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "a says a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's chant: A Says a. 영상.",
                              "Letter A ('애' )",
                              "What letter is this?",
                              "Big A and little a.",
                              "Let's chant: A Says a. 영상.",
                              "Trace the big A and little a.",
                              "A is for apple / A is for apron/ A is for acorn",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "a says a",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Color the pictures starting with a.",
                              "Apple starts with a. Color it.",
                              "Apron starts with a. Color it.",
                              "Banana starts with b.",
                              "Acorn starts with a. Color it.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/07/07_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "wiggle wiggle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out  the window. ",
                              "창문을 가리키며",
                              "It is sunny ",
                              "머리위로 두 손을 마주 잡으며",
                              "It is cloudy ",
                              "양손으로 구름모양으로 만들며",
                              "It is rainy ",
                              "양손으로  비가 내리듯 손가락을 움직이며",
                              "It is snowy",
                              "양손으로 눈이 내리듯 손가락을 움직이며,",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "what comes next",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "This is ice cream.",
                              "Look at the pattern of the ice cream.",
                              "White, red, brown, white...",
                              "What comes next? Red.",
                              "Color the ice cream.",
                              "Put the word sticker on the circle.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/02/02_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-01-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How do you feel today?",
                              "How do you feel?",
                              "happy / sad / angry ",
                              "Are you happy?",
                              "I am happy",
                              "Circle the picture.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "how many scoops",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "How many scoops do you want?",
                              "영상, 상호작용",
                              "Draw ice cream and write the number.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book1/k2_v1_activity.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "wiggle wiggle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "tangram(k2-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's create shapes.",
                              "Tangram Card 준비",
                              "There are two pieces of tangram.",
                              "What shapes are there?",
                              "Put the stickers on the circles when you are done.",
                              "Let's make a square with two triangles.",
                              "Good job! 스티커",
                            ],
                          },
                          {
                            sentences: [
                              "Can you arrange the two tangram pieces?",
                              "Let's make a triangle.",
                              "Good job! 스티커",
                              "Let's make a square.",
                              "Excellent!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_157.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "a says a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's chant: A Says a. 영상.",
                              "Letter A ('애' )",
                              "What letter is this?",
                              "Big A and little a.",
                              "A is for apple / A is for apron/ A is for acorn",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Trace the big B and little b.",
                              "B is for banana / B is for butter / B is for bun",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "b says b(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's chant: B Says b. 영상",
                              "Letter B ('브')",
                              "What letter is this?",
                              "Big B and little b.",
                              "Trace the big B and little b.",
                              "B 로 시작하는 단어를 쓴다.",
                              "B is for banana / B is for butter / B is for bun",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "b says b",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Say the name of each picture. 영상",
                              "Color the pictures starting with b.",
                              "Banana starts with b. Color it.",
                              "Butter starts with b. Color it.",
                              "Acorn starts with a.",
                              "Bun starts with b. Color it.",
                              "Say the name of each picture.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/01/01_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hello song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-01-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "what will happen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's guess.",
                              "What Will Happen? 영상.",
                              "What will happen when it is hot?",
                              "Color the pictures.",
                              "It's hot.",
                              "It will melt.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/03/03_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC song?",
                            ],
                          },
                          {
                            sentences: [
                              "Let's sing the ABC song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "hello friends",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "what comes next(patterns)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/05/05_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "Simon says(k2-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/85/85_01.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "ice cream story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "나는 외쳐요.",
                              "너도 외쳐요.",
                              "우리 모두 아이스크림을 달라고 외쳐요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets blow bubbles(Stella)",
                        videoURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/84/vid/8401.mp4",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ice cream song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Ice Cream Song 영상.",
                              "Let's sing together.",
                              "Look at me.",
                              "I will sing with motions.",
                              "I scream.",
                              "You scream.",
                              "We all scream for ice cream!",
                              "He screams .",
                              "She screams.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the weather song 2",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "wiggle wiggle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-01.jpg?alt=media&token=6f6bdb3c-57bd-4221-8c14-9e55f98b30ee",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-01%20lets%20make.mp4?alt=media&token=39c189ba-9cbe-45ad-97b0-636c1941948a",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-01.png?alt=media&token=019d5d2a-aea3-4f67-a12e-39db0a6b882a",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book1/k2_v1_howTo.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book1/k2_v1_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-01.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book1/k2_v1_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k2-01.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.jpg?alt=media&token=d391b16b-f1f6-4dae-810b-49ea8782e0b3",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.pdf?alt=media&token=2cf08fc9-eab6-4072-ba4a-f5e6db5fc513",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.jpg?alt=media&token=318efe9c-7b8a-417f-8cd4-0581a4904ffd",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.pdf?alt=media&token=4a2cb690-a0f5-4d4a-a0e1-dd8ef79b7fc8",
                },
                {
                  title: "Daily Routine Manual",
                  color: "grey lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 1 Manual",
                  color: "lime lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 2 Manual",
                  color: "teal lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "ice cream story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "ice cream song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "hello song daily routine",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "hello friends",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "wiggle wiggle",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "head and shoulders",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "goodbye song daily routine",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "a says a(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "b says b(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Alphabet Song",
                color: "pink",
                imageURL: "",
                videoTitle: "the alphabet song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k2-02
          {
            volume: "02",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-02-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Feelings Song.",
                              "영상, 율동.",
                              "How do you feel today?",
                              "Draw your feeling.",
                              "How do you feel?",
                              "Are you happy?",
                              "I am happy",
                              "Good job! Drawing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상.",
                              "Let‘s sing together.",
                              "Tuffet, curds and whey, spider",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets count(k1-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: ["Let's Count"],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book1/prek_v1_count.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out the window.",
                              "창문을 가리키며",
                              "It is sunny ",
                              "머리위로 두 손을 마주 잡으며",
                              "It is cloudy ",
                              "양손으로 구름모양으로 만들며",
                              "It is rainy ",
                              "양손으로  비가 내리듯 손가락을 움직이며",
                              "Good job! Singing.",
                              "Circle the picture.",
                              "It is windy ",
                              "양손을 바람 불듯이 움직이며,",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상.",
                              "Let‘s sing together.",
                              "Tuffet, curds and whey, spider",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k2-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What do you see?",
                              "영상, 상호작용.",
                              "Circle the pictures.",
                              "Do you see a (bird/ spider / Squirrel/ dog)?",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat ",
                            ],
                          },
                          {
                            sentences: [
                              "What animals do you like?",
                              "Do you like (Snakes/ cats / dogs/ spiders)?",
                              "Draw your like animals.",
                              "Good job! Drawing",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/58/58_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "morning",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Feelings Song.",
                              "영상, 율동.",
                              "How do you feel today?",
                              "Draw your feeling.",
                              "How do you feel?",
                              "Are you happy?",
                              "I am happy",
                              "Good job! Drawing",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상.",
                              "Let‘s sing together.",
                              "Tuffet, curds and whey, spider",
                              "Good job! Singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "sound it out(k2-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Sound It Out 영상",
                              "Can you say Muffet / tuffet?",
                              "Let's say (Muffet / tuffet).",
                              "Let's play a game. 영상.",
                              "Clap your hands when you hear the words 'Muffet' or 'tuffet.'",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat ",
                            ],
                          },
                          {
                            sentences: [
                              "Is she frightened by the (squirrel)?",
                              "Circle the picture.",
                              "Squirrel / bird / spider / dog ",
                              "Excellent!",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/57/57_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "teddy bear",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-02-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Q Button - what month is it?",
                              "month screen - March ",
                              "음표 - 'month song'",
                              "week - bar",
                              "음표 - 'week song'",
                              "(Sunday/ Monday/...)",
                              "Next",
                              "what day is it  Today?",
                              "Next - Today is...",
                              "(다른 날일 경우 Try again )",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상",
                              "Let‘s sing together.",
                              "Tuffet, curds and whey, spider",
                              "I will sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Alphabet Song.",
                              "영상의 Alphabet을 point하며,",
                            ],
                          },
                          {
                            sentences: [
                              "Do you know the Alphabet Song?",
                              "Let's sing the Alphabet Song.",
                              "ABCDEFG HIJKLMN",
                              "OPQ RST UVW XYZ.",
                              "Now, I know my ABC's.",
                              "Next time won't you sing with me.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "walking walking",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Walking Walking",
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "c says c(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's chant: C Says c 영상.",
                              "Letter C('크')",
                              "What letter is this? Big C and little c.",
                              "Trace the big C and little c.",
                              "C is for cookie / C is for cake / C is for carrot",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상",
                              "Let‘s sing together.",
                              "Tuffet, curds and whey, spider",
                              "I will sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "c says c",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Say the name of each picture. 영상을 본다.",
                              "Color the pictures starting with c.",
                              "Cake starts with c. Color it.",
                              "Carrot starts with c. Color it.",
                              "Banana starts with b./ Cookie starts with c. Color it.",
                            ],
                          },
                          {
                            sentences: [
                              "Say the name of each picture.",
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/72/72_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "teddy bear",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What's the weather like today?",
                              "Look out  the window.",
                              "It is sunny,",
                              "It is cloudy,",
                              "It is rainy,",
                              "It is windy,",
                              "Good job! Singing.",
                              "Circle the picture.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상",
                              "Let‘s sing together.",
                              "Tuffet, curds and whey, spider",
                              "I will sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "there came a spider",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's talk about a spider.",
                              "Spiders have two body parts.",
                              "The spider has 8 legs.",
                              "영상, 상호작용.",
                              "Draw the legs.",
                              "Put the matching sticker on the circle.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "How many legs are there?",
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/56/56_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "animal song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing ",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-02-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the Feelings Song.",
                              "영상, 율동.",
                              "How do you feel today?",
                              "Are you happy?",
                              "I am happy",
                              "Circle the picture.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상",
                              "Let's sing together.",
                              "Tuffet, curds and whey, spider",
                              "I will sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "patterns(k2-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's look at the tuffet.",
                              "What colors do you see?",
                              "I see orange / I see white / I see black",
                              "Today we will learn the pattern.",
                            ],
                          },
                          {
                            sentences: [
                              "Orange, white, black, white.",
                              "What comes next?",
                              "Orange comes next.",
                              "영상, 상호작용.",
                              "Can you make your own patterns?",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat ",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book2/k2_v2_pattern.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "animal song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing ",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "what am i(k2-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's look at the shadows.",
                              "We need a flashlight,",
                              "Forks and a rubber glove.",
                              "Can you make shadow animals?",
                              "Let's look at the shadow animals.",
                              "영상, 상호작용.",
                              "Circle the animals you see.",
                              "deer/ bird / dog / rooster",
                              "What animals can you make?",
                              "Put the sticker on the circle.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_158.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상",
                              "Let's sing together.",
                              "Tuffet, curds and whey, spider",
                              "I will sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "c says c(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's chant: C Says c 영상",
                              "Letter C('크')",
                              "What letter is this?  Big C and little c.",
                              "C is for cookie / C is for cake / C is for carrot",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "morning",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "d says d(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's chant: D Says d 영상",
                              "Letter D('드')",
                              "What letter is this? Big D and little d.",
                              "Trace the big D and little d.",
                              "D is for doughnut / D is for dish / D is for dumpling",
                              "Put the matching stickers on the circles.",
                              "Good job!",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상",
                              "Let's sing together.",
                              "Tuffet, curds and whey, spider",
                              "I will sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "d says d",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Say the name of each picture. D d 영상",
                              "Color the pictures starting with d.",
                              "Dish starts with d. Color it.",
                              "Doughnut starts with d. Color it.",
                              "Bun starts with b. / Dumpling starts with d. Color it.",
                              "Say the name of each picture.",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "min ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/67/67_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "teddy bear",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job! Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-02-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "The ABC Song. 영상",
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                            ],
                          },
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상",
                              "Let's sing together.",
                              "Tuffet, curds and whey, spider",
                              "I will sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "green",
                        imageURL: "",
                        videoTitle: "tangram(k2-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Tangram 영상",
                              "Let's create shapes.",
                              "Tangram card 준비",
                              "There are three pieces of a tangram.",
                              "What shapes are they?",
                              "Let's make a square with two triangles.",
                              "Good job!",
                              "There are three triangles.",
                              "Can you put them together?",
                              "What does it look like?",
                              "Let's make a square.",
                              "Excellent!",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/k1v4_vid4.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "animal song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "who am i(k2-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's guess. Who Am I?",
                              "I have 2 legs.",
                              "I have 2 eyes.",
                              "I have 2 wings.",
                              "영상,",
                              "I am a ...",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji Come up here",
                              "Circle the pictures",
                              "Good job!  High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/55/55_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상",
                              "Let's sing together.",
                              "Tuffet, curds and whey, spider",
                              "I will sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "green",
                        imageURL: "",
                        videoTitle: "what comes next(k2-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "What Comes Next?",
                              "Do you see patterns?",
                              "Little Miss Muffet / tuffet / squirrel / spider / bird",
                              "What Comes Next?",
                              "영상, 상호작용.",
                              "Put the stickers on the circles.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/54/54_01.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "walking walking",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions. ",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little miss Muffet story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "소녀 머팻이",
                              "낮은 의자에 앉아",
                              "코티지 치즈를 먹고 있어요.",
                              "그런데 큰 거미가 다가와서",
                              "소녀 머팻 옆에 앉았어요.",
                              "그래서 소녀 머팻은 놀라서 도망갔어요.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets move little miss Muffet",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "There is a spider.",
                              "Left / Right",
                              "영상, 상호작용",
                              "Put the sticker on the circle.",
                              "Good job!",
                            ],
                          },
                          {
                            sentences: [
                              "Who wants to come up here?",
                              "Raise your hands.",
                              "Min Ji Come up here",
                              "Circle the pictures",
                              "Good job! High five",
                              "Go back to your seat",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/53/53_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "little miss Muffet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Little Miss Muffet 영상",
                              "Let's sing together.",
                              "Tuffet, curds and whey, spider",
                              "I will sing with motions.",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "green",
                        imageURL: "",
                        videoTitle: "",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "morning",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing with motions.",
                              "Good job!  Singing",
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-02.jpg?alt=media&token=35754087-3dc9-41b0-8a0a-4bc462587094",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-02%20lets%20make.mp4?alt=media&token=744e0ed9-08b1-4457-b868-5b8189a6eb8f",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-02.png?alt=media&token=84f58045-882c-448e-ba41-44d64287f279",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book2/k2_v2_howTo.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book2/k2_v2_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-02.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book2/k2_v2_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k2-02.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-02%20teacher%20supplement.jpg?alt=media&token=acc172ba-a5b9-41bb-9ba6-757a07535ea2",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-02%20teacher%20supplement.pdf?alt=media&token=4f25dcf6-18fe-4925-8298-fc0f49f7452f",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-02%20worksheets.jpg?alt=media&token=c5483f37-2253-4811-8c76-71f3312b29c4",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-02%20worksheets.pdf?alt=media&token=afe79bc4-86e2-45f6-b225-57fc47884473",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "little miss Muffet story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "little miss Muffet song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "feelings",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "animal song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "walking walking",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "teddy bear",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-4",
                color: "orange",
                imageURL: "",
                videoTitle: "the weather song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "c says c(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "d says d(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "ABC Song",
                color: "amber",
                imageURL: "",
                videoTitle: "ABC song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [
                      "Let's sing the ABC Song.",
                      "Do you know the ABC Song?",
                      "Let's sing the ABC Song.",
                      "ABCDEFG, HIJKLMNOP,",
                      "QRS, TUV, WX and Y and Z.",
                      "I say A. You say BC! A, BC! A, BC!",
                      "Now I know my ABCs, ABCs, ABCs!",
                      "Everybody come on and sing the sounds!",
                      "Good job singing.",
                    ],
                  },
                ],
              },
            ],
          },

          // k2-03
          {
            volume: "03",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-03-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k2-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/27/27_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "finger family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "sound it out(k2-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/28/28_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-03-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "d says d(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "hello song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "e says e(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "e says e",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/68/68_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "finger family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "teapot family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/29/29_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-03-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets have tea",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book3/k2_v3_activity.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "make five(k2-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_160.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "e says e(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "happy family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "f says f(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "f says f",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/69/69_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hello song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-03-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "who am i(k2-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/30/30_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "happy family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "teapots with shapes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_161.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "finger family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i am a little teapot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets move Robby(k2-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/31/31_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "i am a little teapot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "green",
                        imageURL: "",
                        videoTitle: "lets blow bubbles(Stella)",
                        videoURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/84/vid/8401.mp4",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "hello song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-03.jpg?alt=media&token=71b76714-3f0b-49f9-b653-8278a4e4a6f0",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-03%20lets%20make.mp4?alt=media&token=3fa58d71-ef1d-4d4a-a656-0f16e14200da",
                  htmlURL: "l",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-03.png?alt=media&token=85be991a-f5a4-4dfe-a31f-07cd58d65e84",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book3/k2_v3_howTo.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book3/k2_v3_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-03.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book3/k2_v3_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k2-03.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.jpg?alt=media&token=d391b16b-f1f6-4dae-810b-49ea8782e0b3",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.pdf?alt=media&token=2cf08fc9-eab6-4072-ba4a-f5e6db5fc513",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.jpg?alt=media&token=318efe9c-7b8a-417f-8cd4-0581a4904ffd",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.pdf?alt=media&token=4a2cb690-a0f5-4d4a-a0e1-dd8ef79b7fc8",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "i am a little teapot story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "i am a little teapot song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "wash my hands",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "open shut them",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "happy family",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "finger family",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-8",
                color: "orange",
                imageURL: "",
                videoTitle: "colors song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "e says e(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Activity-18",
                color: "pink",
                imageURL: "",
                videoTitle: "f says f(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Alphabet Song",
                color: "pink",
                imageURL: "",
                videoTitle: "the alphabet song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k2-04
          {
            volume: "04",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-04-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k2-04)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_12.html",
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "my town",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "at the market",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_162.html",
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "hidden pictures(k2-04)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k1/book4/k1_v4_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-04-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "f says f(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "g says g(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "g says g",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_15.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "open shut them",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "counting cars",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_14.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "my town",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-04-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "my dream",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/33/33_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "my town",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "opposite(k2-04)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_163.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "g says g(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "h says h(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "h says h",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/70/70_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-04-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "find the muffin man",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/activity/muffinPage.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "my town",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "different jobs and belongings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/34/34_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the muffin man story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "memory game(k2-04)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the muffin man song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "green",
                        imageURL: "",
                        videoTitle: "",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "open shut them",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-04.jpg?alt=media&token=ec7999a2-80b3-4f24-ad28-dc7bd5ff1d27",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-04%20lets%20make.mp4?alt=media&token=9302eebb-b16b-4b6c-adf5-aab957eb96ff",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-04.png?alt=media&token=bcc3807a-204a-49b8-b325-1f8c60de9228",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k2/book4/k2_v4.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book4/k2_v4_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-04.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book4/k2_v4_assessmentA.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/K2-04.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.jpg?alt=media&token=d391b16b-f1f6-4dae-810b-49ea8782e0b3",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.pdf?alt=media&token=2cf08fc9-eab6-4072-ba4a-f5e6db5fc513",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.jpg?alt=media&token=318efe9c-7b8a-417f-8cd4-0581a4904ffd",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.pdf?alt=media&token=4a2cb690-a0f5-4d4a-a0e1-dd8ef79b7fc8",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "the muffin man story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "the muffin man song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "brush my teeth",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "one little finger",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "my town",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "three little monkeys",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-12",
                color: "cyan",
                imageURL: "",
                videoTitle: "numbers song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "g says g(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "h says h(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "ABC Song",
                color: "green",
                imageURL: "",
                videoTitle: "ABC song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [
                      "Let's sing the ABC Song.",
                      "Do you know the ABC Song?",
                      "Let's sing the ABC Song.",
                      "ABCDEFG, HIJKLMNOP,",
                      "QRS, TUV, WX and Y and Z.",
                      "I say A. You say BC! A, BC! A, BC!",
                      "Now I know my ABCs, ABCs, ABCs!",
                      "Everybody come on and sing the sounds!",
                      "Good job singing.",
                    ],
                  },
                ],
              },
            ],
          },

          // k2-05
          {
            volume: "05",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-05-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "thank you song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k2-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_35.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i like to eat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "sound it out(k2-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_30.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-05-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-9",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "what does he need to cut the bread",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_31.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "h says h(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "i like to eat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i says i(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "i says i",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_29.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "before and after",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "wedding cake",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_28.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-05-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets count(k2-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/activity/countPage.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "dots and boxes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i says i(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "before and after",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "j says j(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "j says j",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_32.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-05-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "who am i(k2-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_33.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "before and after",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "how to wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_164.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "lets move Tucker",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_34.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "i like to eat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k2-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book5/k2_v5_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "little Tommy Tucker song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "green",
                        imageURL: "",
                        videoTitle: "beans grow",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-05.jpg?alt=media&token=657ae067-8685-4866-abff-69ebc3d379d7",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-05%20lets%20make.mp4?alt=media&token=78dbe181-3a3a-4927-ac0e-22ec50b6de3c",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-05.png?alt=media&token=17fd5c2c-bf76-449d-872c-c7c660386229",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k2/book5/k2_v5.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book5/k2_v5_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-05.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book5/k2_v5_assessmentB.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k2-05.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.jpg?alt=media&token=d391b16b-f1f6-4dae-810b-49ea8782e0b3",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.pdf?alt=media&token=2cf08fc9-eab6-4072-ba4a-f5e6db5fc513",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.jpg?alt=media&token=318efe9c-7b8a-417f-8cd4-0581a4904ffd",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.pdf?alt=media&token=4a2cb690-a0f5-4d4a-a0e1-dd8ef79b7fc8",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "little Tommy Tucker story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },

              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "little Tommy Tucker song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "before and after",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "thank you song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "yummy fruits",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "i like to eat",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "shapes song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "i says i(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "j says j(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Alphabet Song",
                color: "orange",
                imageURL: "",
                videoTitle: "the alphabet song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k2-06
          {
            volume: "06",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-06-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "days of the week song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "happy birthday",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k2-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_50.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "food calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_53.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-06-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "j says j(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "k says k(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "k says k",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_52.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "days of the week song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "days of the week",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_51.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-06-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "k says k(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "l says l(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "l says l",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_54.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "happy birthday",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "party banners",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book6/k2_v6_activity.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "look inside",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/activity/lookInsidePage.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-06-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "drawing game(k2-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_56.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "l says l(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "m says m(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "m says m",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_55.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "today is monday story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k2-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book6/k2_v6_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "today is monday song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "lets move Robby(k2-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_57.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "happy birthday",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-06.jpg?alt=media&token=18256b34-9fef-4200-b587-d5e5658b824f",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-06%20lets%20make.mp4?alt=media&token=89090c9f-8f51-49de-9f18-08716c7df7b3",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-06.png?alt=media&token=590f913a-3a39-418f-a974-bf723ece56c1",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k2/book6/k2_v6.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book6/k2_v6_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-06.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book6/k2_v6_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k2-06.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.jpg?alt=media&token=d391b16b-f1f6-4dae-810b-49ea8782e0b3",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.pdf?alt=media&token=2cf08fc9-eab6-4072-ba4a-f5e6db5fc513",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.jpg?alt=media&token=318efe9c-7b8a-417f-8cd4-0581a4904ffd",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.pdf?alt=media&token=4a2cb690-a0f5-4d4a-a0e1-dd8ef79b7fc8",
                },
                {
                  title: "Daily Routine Manual",
                  color: "grey lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 1 Manual",
                  color: "lime lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 2 Manual",
                  color: "teal lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "today is monday story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "today is monday song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "sorry excuse me",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "happy birthday",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "special days",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "mr Geneus",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "days of the week song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "k says k(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "amber",
                imageURL: "",
                videoTitle: "l says l(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "amber",
                imageURL: "",
                videoTitle: "m says m(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k2-07
          {
            volume: "07",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-07-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "grandmas house",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k2-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_66.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "where are they",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_67.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "go and stop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-07-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "n says n(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "cross the street",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "n says n",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_68.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "go and stop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "same or different(k2-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_70.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "tangram(k2-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_165.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-07-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "n says n(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "go and stop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "o says o(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "o says o",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_69.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "grandmas house",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "left and right",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book7/k2_v7_activity1.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "cross the street",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-07-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "o says o(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "p says p(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "p says p",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_71.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "coding memory game",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "cross the street",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k2-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book7/k2_v7_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "five little men in a flying saucer song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "green",
                        imageURL: "",
                        videoTitle: "lets roll",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "go and stop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-07.jpg?alt=media&token=6e07363a-5a57-4ac6-852b-c1210d777570",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-07%20lets%20make.mp4?alt=media&token=017ce899-33f9-4c39-ba6a-534e0dca6b23",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-07.png?alt=media&token=a20041d7-2f2d-4efc-bcde-364b3c914346",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k2/book7/k2_v7.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book7/k2_v7_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-07.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book7/k2_v7_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k2-07.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.jpg?alt=media&token=d391b16b-f1f6-4dae-810b-49ea8782e0b3",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.pdf?alt=media&token=2cf08fc9-eab6-4072-ba4a-f5e6db5fc513",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.jpg?alt=media&token=318efe9c-7b8a-417f-8cd4-0581a4904ffd",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.pdf?alt=media&token=4a2cb690-a0f5-4d4a-a0e1-dd8ef79b7fc8",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "five little men in a flying saucer story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "five little men in a flying saucer song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "cross the street",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "go and stop",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "grandmas house",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "the wheels on the bus",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "numbers song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "pink",
                imageURL: "",
                videoTitle: "n says n(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "pink",
                imageURL: "",
                videoTitle: "o says o(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "green",
                imageURL: "",
                videoTitle: "p says p(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k2-08
          {
            volume: "08",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-08-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "months song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the picture(k2-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_92.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "Simon says(k2-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_278.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "this is the way",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-08-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "q says q(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "q says q",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_93.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "same or different(k2-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book8/k2_v8_activity1.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "choose your tool",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_96.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "use the potty",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-08-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "q says q(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "r says r(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "r says r",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_94.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "this is the way",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "what do we need",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_95.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "use the potty",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-08-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "r says r(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "s says s(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "this is the way",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "s says s",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_97.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "what am i(k2-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_98.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "use the potty",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k2-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book8/k2_v8_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "peanut butter and jelly song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "green",
                        imageURL: "",
                        videoTitle: "shape world",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-08.jpg?alt=media&token=23dd1d4a-29d4-4620-ad09-f1e2f4e25e5d",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-08%20lets%20make.mp4?alt=media&token=5ed814a3-0f67-43ef-97bf-1f486a0bb83c",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-08.png?alt=media&token=e650662c-caf9-4283-a5a4-853546962a90",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k2/book8/k2_v8.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book8/k2_v8_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-08.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book8/k2_v8_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k2-08.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.jpg?alt=media&token=d391b16b-f1f6-4dae-810b-49ea8782e0b3",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.pdf?alt=media&token=2cf08fc9-eab6-4072-ba4a-f5e6db5fc513",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.jpg?alt=media&token=318efe9c-7b8a-417f-8cd4-0581a4904ffd",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.pdf?alt=media&token=4a2cb690-a0f5-4d4a-a0e1-dd8ef79b7fc8",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "peanut butter and jelly story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "peanut butter and jelly song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "use the potty",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "the clock",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "in the kitchen",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "this is the way",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "months song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "pink",
                imageURL: "",
                videoTitle: "q says q(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "pink",
                imageURL: "",
                videoTitle: "r says r(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "green",
                imageURL: "",
                videoTitle: "s says s(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k2-09
          {
            volume: "09",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-09-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "seasons song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Pretend to be a tree while you are singing.",
                              "Let's sing with motions.",
                              "Spring, Summer, Fall, Winter",
                              "Spring, Summer, Fall, Winter",
                              "These are the four seasons.",
                              "Spring, Summer, Fall, Winter",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "seasons and food",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_112.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k2-09)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_113.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "rain rain go away",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "hot and cold",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_279.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-09-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "pat pat pat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "t says t(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "t says t",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_114.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "rain rain go away",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "play in the winter",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_116.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-09-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "t says t(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "u says u(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "rain rain go away",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "u says u",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_115.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "stretching song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "pat pat pat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "mittens",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/activity/mitten.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "snowflakes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_117.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-09-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "tic tac toe",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "u says u(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "v says v(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "green",
                        imageURL: "",
                        videoTitle: "v says v",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_118.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "pat pat pat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "peas porridge hot story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k2-09)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book9/k2_v9_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "peas porridge hot song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "lets move the boy",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_119.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-09.jpg?alt=media&token=97757386-38ca-449b-8d10-96492ea018a6",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-09%20lets%20make.mp4?alt=media&token=85f064f4-4711-4b6b-bcc1-9f38a374caf9",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-09.png?alt=media&token=b4c6dac0-3ff7-4129-a73b-2e105da19462",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k2/book9/k2_v9.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book9/k2_v9_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-09.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book9/k2_v9_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k2-09.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.jpg?alt=media&token=d391b16b-f1f6-4dae-810b-49ea8782e0b3",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.pdf?alt=media&token=2cf08fc9-eab6-4072-ba4a-f5e6db5fc513",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.jpg?alt=media&token=318efe9c-7b8a-417f-8cd4-0581a4904ffd",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.pdf?alt=media&token=4a2cb690-a0f5-4d4a-a0e1-dd8ef79b7fc8",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "peas porridge hot story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "peas porridge hot song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "cover your mouth",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "merry christmas",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "pat pat pat",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "rain rain go away",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-13",
                color: "amber",
                imageURL: "",
                videoTitle: "stretching song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                imageURL: "",
                videoTitle: "t says t(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "amber",
                imageURL: "",
                videoTitle: "u says u(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "amber",
                imageURL: "",
                videoTitle: "v says v(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k2-10
          {
            volume: "10",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k2-10-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "thank you song 2",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "manners",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets look at the cover(k2-10)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_136.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the green string",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        imageURL: "",
                        videoTitle: "roll your hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "lets grow plants",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "i love earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k2-10-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "w says w(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "twinkle twinkle little star",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-10",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "w says w",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_137.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "heavy vegetables",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_139.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-11",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "x says x(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "x says x",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_138.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the green string",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k2-10-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "friendship song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "lets recycle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book10/k2_v10_activity1.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "manners",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "build the cup tower",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_166.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "x says x(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "i love earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        imageURL: "",
                        videoTitle: "y says y(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-18",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "y says y",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_140.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "twinkle twinkle little star",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k2-10-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "y says y(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-19",
                        color: "green",
                        imageURL: "",
                        videoTitle: "z says z(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the green string",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "z says z",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_141.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        imageURL: "",
                        videoTitle: "lets move the farmer",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_142.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "i love earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "the farmers story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        imageURL: "",
                        videoTitle: "hidden pictures(k2-10)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book10/k2_v10_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        imageURL: "",
                        videoTitle: "the farmers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "green",
                        imageURL: "",
                        videoTitle: "musical kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        imageURL: "",
                        videoTitle: "manners",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K2-10.jpg?alt=media&token=f92bcca2-11b5-4b5a-83c8-56210958a005",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk2-10%20lets%20make.mp4?alt=media&token=19a3f0a6-4829-46ad-9003-d8caec2aae94",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K2-10.png?alt=media&token=3657d23b-022e-48c4-b7d5-4b410b13ebde",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k2/book10/k2_v10.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book10/k2_v10_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K2-10.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book10/k2_v10_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k2-10.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.jpg?alt=media&token=d391b16b-f1f6-4dae-810b-49ea8782e0b3",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20teacher%20supplement.pdf?alt=media&token=2cf08fc9-eab6-4072-ba4a-f5e6db5fc513",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.jpg?alt=media&token=318efe9c-7b8a-417f-8cd4-0581a4904ffd",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK2-01%20worksheets.pdf?alt=media&token=4a2cb690-a0f5-4d4a-a0e1-dd8ef79b7fc8",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                imageURL: "",
                videoTitle: "the farmers story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                imageURL: "",
                videoTitle: "the farmers song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "thank you song 2",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "twinkle twinkle little star",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "the green string",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-13",
                color: "pink",
                imageURL: "",
                videoTitle: "friendship song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "pink",
                imageURL: "",
                videoTitle: "w says w(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-11",
                color: "cyan",
                imageURL: "",
                videoTitle: "x says x(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },

              {
                name: "Phonics-18",
                color: "pink",
                imageURL: "",
                videoTitle: "y says y(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-19",
                color: "green",
                imageURL: "",
                videoTitle: "z says z(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },
        ],
      },

      // level 3
      {
        level: "K3",
        color: "brown",
        books: [
          // k3-01
          {
            volume: "01",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-01-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        videoTitle: "hello song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "cyan",
                        videoTitle: "lets count(k1-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/pre-k/book1/prek_v1_count.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the cover(k3-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/08/08_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "hello friends",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        videoTitle: "where is the cookie jar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/12/12_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "wiggle wiggle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-01-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "The Alhpabet song",
                        color: "pink",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "hello friends",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "short a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "short a",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/09/09_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "cyan",
                        videoTitle: "my cookie jar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/73/73_01.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "pink",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "before and after",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-01-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "lets share the cookies",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book1/k3_v1_activity.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        videoTitle: "who stole the cookies game",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/10/10_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "wiggle wiggle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "short a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "before and after",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "the school",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        videoTitle: "sight words this a",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/11/11_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-01-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        videoTitle: "sharing is caring",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/74/74_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "green",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "before and after",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "amber",
                        videoTitle: "shape patterns",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/75/75_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "green",
                        videoTitle: "Simon says(k3-01)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "wiggle wiggle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 1,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle:
                          "who stole the cookies from the cookie jar story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-1",
                        color: "amber",
                        videoTitle: "lets make bubbles(Stella)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle:
                          "who stole the cookies from the cookie jar song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity",
                        color: "green",
                        videoTitle: "the weather song 2",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "head and shoulders",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-01.jpg?alt=media&token=17f8cc78-9d9f-4269-8a07-64f41e1f7ed9",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-01%20lets%20make.mp4?alt=media&token=6a3bca7d-69fb-4d2b-baa9-9e82a7333bb8",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-01.png?alt=media&token=e4372c55-199e-4d8a-9fea-5f5b7535f3db",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book1/k3_v1_howTo.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book1/k3_v1_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-01.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book1/k3_v1_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-01.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-01%20teacher%20supplement.jpg?alt=media&token=22b3d85d-a5f6-4fda-bb12-63b68875d939",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-01%20teacher%20supplement.pdf?alt=media&token=591f27b6-2619-4f47-9582-d3449e997060",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-01%20worksheets.jpg?alt=media&token=e7f3dea3-ee6a-4964-ad17-fb0b479f4ed4",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-01%20worksheets.pdf?alt=media&token=d0c2ab62-9177-4242-874c-dabfd454781c",
                },
                {
                  title: "Daily Routine Manual",
                  color: "grey lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 1 Manual",
                  color: "lime lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 2 Manual",
                  color: "teal lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                videoTitle: "who stole the cookies from the cookie jar story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                videoTitle: "who stole the cookies from the cookie jar song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                videoTitle: "hello song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "hello friends",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "wiggle wiggle",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "head and shoulders",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-8",
                color: "orange",
                videoTitle: "feelings",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },

              {
                name: "The Alhpabet song",
                color: "pink",
                videoTitle: "the alphabet song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "short a(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                videoTitle: "the school",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k3-02
          {
            volume: "02",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-02-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "morning",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the cover(k3-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/65/65_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "goodbye song daily routine",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        videoTitle: "sound it out(k3-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "walking walking",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-02-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "short a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "morning",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "short e(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "short e",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/64/64_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "walking walking",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "cyan",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        videoTitle: "shapes(k3-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_277.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "animal song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-02-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "what time is it",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book2/k3_v2_activity.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        videoTitle: "riddle what am i",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/62/62_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "the clock",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        videoTitle: "tangram(k3-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "the school",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "animal song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 2,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "the animal jumps",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        videoTitle: "sight words the jump",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/61/61_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "morning",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-02-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        videoTitle: "who am i(k3-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/60/60_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "animal song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 2,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-21",
                        color: "amber",
                        videoTitle: "the animal jumps",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "green",
                        videoTitle: "the and jump",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_169.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "walking walking",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "chick chick chick chick chicken story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "lets move the girl",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/59/59_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "chick chick chick chick chicken song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "green",
                        videoTitle: "tangram(k3-02)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "goodbye song daily routine",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-02.jpg?alt=media&token=c15b73ca-584b-4018-9448-ebce5b2b6dac",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-02%20lets%20make.mp4?alt=media&token=fd9f94e2-ce49-4722-a12a-2443a2c5f0ec",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-02.png?alt=media&token=db38be44-dcd4-44cb-8338-0e3e271c3109",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book2/k3_v2_howTo.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book2/k3_v2_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-02.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book2/k3_v2_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-02.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-02%20teacher%20supplement.jpg?alt=media&token=3d930a02-47f7-449d-9e19-7f3b6951e52f",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-02%20teacher%20supplement.pdf?alt=media&token=ebcfb2b6-669c-45a5-b955-6537edeb6435",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-02%20worksheets.jpg?alt=media&token=36a5313b-3ce9-4e4f-8bbf-ab7b0d7d8fff",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-02%20worksheets.pdf?alt=media&token=eebce6a5-93e9-418d-8ac1-81ab77c16caf",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                videoTitle: "chick chick chick chick chicken story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                videoTitle: "chick chick chick chick chicken song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "animal song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "walking walking",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "teddy bear",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine",
                color: "cyan",
                videoTitle: "shapes song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "goodbye song daily routine",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "ABC Song",
                color: "green",
                videoTitle: "ABC song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [
                      "Let's sing the ABC Song.",
                      "Do you know the ABC Song?",
                      "Let's sing the ABC Song.",
                      "ABCDEFG, HIJKLMNOP,",
                      "QRS, TUV, WX and Y and Z.",
                      "I say A. You say BC! A, BC! A, BC!",
                      "Now I know my ABCs, ABCs, ABCs!",
                      "Everybody come on and sing the sounds!",
                      "Good job singing.",
                    ],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "short e(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                videoTitle: "the animal jumps",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k3-03
          {
            volume: "03",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-03-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        videoTitle: "days of the week song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        imageURL: "",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the cover(k3-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/18/18_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "finger family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        videoTitle: "my house",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/19/19_01.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "happy family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-03-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "short e",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/64/64_01.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "short i(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "short i",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/20/20_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "cover your mouth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "cyan",
                        videoTitle: "finger family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        videoTitle: "family members",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/21/21_01.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "happy family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-03-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "lets buy some buns",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book3/k3_v3_activity.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        videoTitle: "make five game(Stella)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "cover your mouth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        videoTitle: "make five(k3-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_170.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "the animal jumps",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "roll your hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "this is my family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-9",
                        color: "amber",
                        videoTitle: "sight words is my",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/22/22_01.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-03-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        videoTitle: "make five card game(Stella)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-21",
                        color: "green",
                        videoTitle: "sight word my",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/22/22_01.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "finger family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "lets move Robby(k3-03)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/24/24_01.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "happy family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "hot cross buns story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        videoTitle: "lets blow bubbles(Stella)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "hot cross buns song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "green",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "wash my hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-03.jpg?alt=media&token=dab7336e-41f6-4649-b31b-d640c74dfb98",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-03%20lets%20make.mp4?alt=media&token=c35a237b-483f-415f-aadc-eff875d6f961",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-03.png?alt=media&token=c7d9d897-c1af-494e-90eb-69d4e0ca2b31",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book3/k3_v3_howTo.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book3/k3_v3_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-03.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book3/k3_v3_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-03.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-03%20teacher%20supplement.jpg?alt=media&token=ac402cca-27a7-4869-9752-3d7b8231284f",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-03%20teacher%20supplement.pdf?alt=media&token=1a4a0907-a08d-4701-8416-bd955a4c1286",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-03%20worksheets.jpg?alt=media&token=b1104b78-ede3-40e1-83ac-72a87d60aa29",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-03%20worksheets.pdf?alt=media&token=9ffa0715-315b-4e04-99d1-0cc23a96abf9",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                videoTitle: "hot cross buns story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                videoTitle: "hot cross buns song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-4",
                color: "orange",
                videoTitle: "the weather song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "open shut them",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "happy family",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "finger family",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },

              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "days of the week song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "amber",
                imageURL: "",
                videoTitle: "the alphabet song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "short i(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                videoTitle: "this is my family",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k3-04
          {
            volume: "04",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-04-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the cover(k3-04)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_03.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "my town",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        videoTitle: "things to do",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_04.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-04-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "short i(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "short o(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "short o",
                        videoURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book4/videos/k3_phonic1.mp4",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL: "",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "cyan",
                        videoTitle: "my town",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        videoTitle: "favorite jobs",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_05.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "open shut them",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-04-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "who has more",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/activity/cardInit.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        videoTitle: "mirror puzzle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_07.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        videoTitle: "what is wrong",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_09.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "this is my family",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "thank you song 2",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "i see fruits and vegetables",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        videoTitle: "sight words i see",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_08.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-04-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        videoTitle: "to the house",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_10.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "open shut them",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "how to brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_11.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "green",
                        videoTitle: "brush my teeth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "clean up song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "the queen of hearts story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "amber",
                        videoTitle: "my town",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "the queen of hearts song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "green",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-04.jpg?alt=media&token=8d6ff4ef-f90d-44b3-b3b2-11eef95c1de3",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-04%20lets%20make.mp4?alt=media&token=ffcab85c-e26f-47b2-aaea-af15f68a4419",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-04.png?alt=media&token=c0016c3a-ebde-480c-9cd6-5fcaf1e01ef2",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k3/book4/k3_v4.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book4/k3_v4_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-04.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book4/k3_v4_assessmentA.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-04.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-04%20teacher%20supplement.jpg?alt=media&token=8f7448f4-edf5-49b1-a71e-624fec0c68bc",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-04%20teacher%20supplement.pdf?alt=media&token=5e32202c-82a7-4aaf-8707-e4be01b5bb2a",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-04%20worksheets.jpg?alt=media&token=4941d5e1-eaae-4c0f-8d39-9695ada989e6",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-04%20worksheets.pdf?alt=media&token=bc7a290f-d9c5-4b1e-98f2-4a1ba07a77a0",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                videoTitle: "the queen of hearts story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                videoTitle: "the queen of hearts song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "brush my teeth",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "one little finger",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "my town",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "three little monkeys",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                videoTitle: "colors song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "green",
                videoTitle: "clean up song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "short o(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },

              {
                name: "Phonics-18",
                color: "pink",
                videoTitle: "i see fruits and vegetables",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k3-05
          {
            volume: "05",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-05-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        videoTitle: "months song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "thank you song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the cover(k3-05)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_19.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "feelings",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        videoTitle: "flip the pancakes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_22.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-05-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "short o",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_06.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "thank you song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "short u(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "short u",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_21.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "open shut them",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "cyan",
                        videoTitle: "roll your hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        videoTitle: "Jay wants pancakes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_20.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-05-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "be fair and share",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_23.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        videoTitle: "plants",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_43.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        videoTitle: "dots and boxes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "i see fruits and vegetables",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "thank you song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "lets play",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        videoTitle: "sight words play with",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_24.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "stretching song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-05-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-20",
                        color: "amber",
                        videoTitle: "healthy ladder and slide",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "green",
                        videoTitle: "roll your hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "lets move the boy",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_25.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "five crispy pancakes story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "five crispy pancakes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "green",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "happy birthday",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-05.jpg?alt=media&token=a015f274-5d28-476c-b2f8-77158185e2d9",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-05%20lets%20make.mp4?alt=media&token=35ff627d-6391-4f91-b660-d2383d8de723",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-05.png?alt=media&token=b86a228e-5870-402a-95e5-d0690ac7d5de",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k3/book5/k3_v5.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book5/k3_v5_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-05.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book5/k3_v5_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-05.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-05%20teacher%20supplement.jpg?alt=media&token=2452dfb8-c6c2-435a-9505-637b98ec107c",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-05%20teacher%20supplement.pdf?alt=media&token=88c06f6c-77b6-4dc9-9e97-73771e4908ff",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-05%20worksheets.jpg?alt=media&token=bd09c770-7c39-436b-8c3c-9d871161b372",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-05%20worksheets.pdf?alt=media&token=fc542ab9-4cd9-4fce-aa09-b1ab47704e03",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                videoTitle: "five crispy pancakes story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                videoTitle: "five crispy pancakes song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                imageURL: "",
                videoTitle: "before and after",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "thank you song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "yummy fruits",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "i like to eat",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                videoTitle: "months song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine",
                color: "cyan",
                videoTitle: "roll your hands",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "short u(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                videoTitle: "lets play",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k3-06
          {
            volume: "06",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-06-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the cover(k3-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_44.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "months song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        videoTitle: "hello friends(k3-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_47.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-06-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "cyan",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        videoTitle: "short u(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "thank you song 2",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "long a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "long a",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_46.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        videoTitle: "lets guess and count",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_45.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "happy birthday",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-06-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "dragon pattern",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book6/k3_v6_activity.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        videoTitle: "how to make songpyeon",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "pink",
                        videoTitle: "drawing game(k3-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_49.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "lets play",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "stretching song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "celebration",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        videoTitle: "sight words come to",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_48.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-06-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        videoTitle: "hidden pictures(k3-06)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book6/k3_v6_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "green",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "yummy fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "amber",
                        videoTitle: "clean up song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "mr Geneus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "autumn festival story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "make a flag",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_171.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "autumn festival song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "green",
                        videoTitle: "hidden pictures dark sky", // (k3-01)
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book5/k2_v5_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-06.jpg?alt=media&token=426f79d3-b3e6-489d-bfb2-754c18613ce8",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-06%20lets%20make.mp4?alt=media&token=0121b946-2846-46c9-8e1f-b0611ff77c32",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-06.png?alt=media&token=666c2d1c-c667-4f12-a8d8-023285cea375",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k3/book6/k3_v6.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book6/k3_v6_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-06.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book6/k3_v6_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-06.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-07%20teacher%20supplement.jpg?alt=media&token=baec0863-4dc9-437a-846d-e396ea5d1e87",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-07%20teacher%20supplement.pdf?alt=media&token=0e38c3e6-866c-475e-b80c-c2a3e17d2e2d",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-07%20worksheets.jpg?alt=media&token=57a1baf0-5ede-407a-929b-56e1a58c5f8c",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-07%20teacher%20supplement.pdf?alt=media&token=0e38c3e6-866c-475e-b80c-c2a3e17d2e2d",
                },
                {
                  title: "Daily Routine Manual",
                  color: "grey lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 1 Manual",
                  color: "lime lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Main Lesson Part 2 Manual",
                  color: "teal lighten-2",
                  imageURL: "",
                  URL: "",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                videoTitle: "autumn festival story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                videoTitle: "autumn festival song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "thank you song 2",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "sorry excuse me",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "special days",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "mr Geneus",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                videoTitle: "numbers song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "amber",
                videoTitle: "stretching song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "long a(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                videoTitle: "celebration",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k3-07
          {
            volume: "07",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-07-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-3",
                        color: "cyan",
                        videoTitle: "shapes(k3-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_172.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "grandmas house",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the picture(k3-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_77.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "orange",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        videoTitle: "moon cycle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_78.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-07-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        videoTitle: "long a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "go and stop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "long i(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "long i",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_79.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "cross the street",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        videoTitle: "numbers song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        videoTitle: "more or less",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_80.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-07-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "biggest and smallest",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/activity/moonPage.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        videoTitle: "tangram(k3-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_173.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "grandmas house",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "pink",
                        videoTitle: "friendship song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "celebration",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "go and stop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "transportation",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        videoTitle: "sight words look at",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_81.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "cross the street",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-07-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        videoTitle: "hidden pictures(k3-07)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book7/k3_v7_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "green",
                        videoTitle: "one little finger",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "the wheels on the bus",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "lets move Aiken drum",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_82.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "green",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "cross the street",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "Aiken drum story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        videoTitle: "fast or slow",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "Aiken drum song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "green",
                        videoTitle: "hidden pictures sky", // (k3-02)
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book3/k3_v3_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "go and stop",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-07.jpg?alt=media&token=85a55fd3-0b1b-42cf-9ece-b86c839bcab3",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-07%20lets%20make.mp4?alt=media&token=2fcb1bb5-43af-4acb-81f3-78f2f2329cf1",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-07.png?alt=media&token=6c3bdcd8-d0aa-4230-a8f1-a16109b2740c",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k3/book7/k3_v7.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book7/k3_v7_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-07.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book7/k3_v7_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-07.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-07%20teacher%20supplement.jpg?alt=media&token=baec0863-4dc9-437a-846d-e396ea5d1e87",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-07%20teacher%20supplement.pdf?alt=media&token=0e38c3e6-866c-475e-b80c-c2a3e17d2e2d",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-07%20worksheets.jpg?alt=media&token=57a1baf0-5ede-407a-929b-56e1a58c5f8c",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-07%20teacher%20supplement.pdf?alt=media&token=0e38c3e6-866c-475e-b80c-c2a3e17d2e2d",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                videoTitle: "Aiken drum story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                videoTitle: "Aiken drum song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                videoTitle: "shapes song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "go and stop",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "grandmas house",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "the wheels on the bus",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine",
                color: "pink",
                videoTitle: "friendship song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-12",
                color: "cyan",
                videoTitle: "numbers song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "long i(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                videoTitle: "transportation",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k3-08
          {
            volume: "08",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-08-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        videoTitle: "stretching song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the cover(k3-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_86.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "this is the way",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        videoTitle: "lets pretend",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_87.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-08-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        videoTitle: "long i(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "this is the way",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "long o(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "long o",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_88.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "use the potty",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        videoTitle: "stretching song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        videoTitle: "measuring",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-08-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "same or different(k3-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book8/k3_v8_activity1.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        videoTitle: "using electricity",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_89.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "transportation",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "use the potty",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "tools and machines",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        videoTitle: "sight words one red blue yellow",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_90.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "this is the way",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-08-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        videoTitle: "hidden pictures(k3-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book8/k3_v8_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "green",
                        videoTitle: "friendship song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "in the kitchen",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "lets move(k3-08)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_91.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "green",
                        videoTitle: "thank you song 2",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "sorry excuse me",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "found a peanut story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "amber",
                        videoTitle: "manners can be fun",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "found a peanut song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "green",
                        videoTitle: "hidden pictures pink", // (k3-03)
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book2/k3_v2_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "use the potty",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-08.jpg?alt=media&token=6cbcd65e-5f65-4e55-b971-483335cc9a5d",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-08%20lets%20make.mp4?alt=media&token=cd27a8af-77dd-490f-8d5f-defd0ad70831",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-08.png?alt=media&token=e769bc14-5868-440e-8813-2dbe89250703",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k3/book8/k3_v8.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book8/k3_v8_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-08.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book8/k3_v8_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-08.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-08%20teacher%20supplement.jpg?alt=media&token=f2b4b937-0fa3-4ce4-9ced-33a6a4eb6ba7",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-08%20teacher%20supplement.pdf?alt=media&token=e6acf27d-1b6f-4517-a5c0-93055b0a0595",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-08%20worksheets.jpg?alt=media&token=c34b27fe-ca73-423f-b42a-192579dc0714",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-08%20worksheets.pdf?alt=media&token=1108743c-c424-498c-a784-889512d1f458",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                videoTitle: "found a peanut story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                videoTitle: "found a peanut song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                videoTitle: "stretching song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "the clock",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "in the kitchen",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "this is the way",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-8",
                color: "orange",
                videoTitle: "colors song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Activity-2",
                color: "green",
                videoTitle: "thank you song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "long o(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                videoTitle: "tools and machines",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },

          // k3-09
          {
            volume: "09",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-09-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-3",
                        color: "orange",
                        videoTitle: "seasons song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Pretend to be a tree while you are singing.",
                              "Let's sing with motions.",
                              "Spring, Summer, Fall, Winter",
                              "Spring, Summer, Fall, Winter",
                              "These are the four seasons.",
                              "Spring, Summer, Fall, Winter",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "pat pat pat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the cover(k3-09)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_107.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "rain rain go away",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-8",
                        color: "orange",
                        videoTitle: "shapes song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-8",
                        color: "cyan",
                        videoTitle: "seasonal activities",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_108.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-09-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "cyan",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        videoTitle: "long o(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "rain rain go away",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "long u(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "long u",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_109.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-12",
                        color: "cyan",
                        videoTitle: "seasons song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Pretend to be a tree while you are singing.",
                              "Let's sing with motions.",
                              "Spring, Summer, Fall, Winter",
                              "Spring, Summer, Fall, Winter",
                              "These are the four seasons.",
                              "Spring, Summer, Fall, Winter",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        videoTitle: "four seasons",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_110.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-09-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "clothes",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/activity/summerClose.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "amber",
                        videoTitle: "goodbye song daily routine",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "merry christmas",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Alphabet Song",
                        color: "pink",
                        videoTitle: "the alphabet song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "tools and machines",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "pat pat pat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "four seasons song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Pretend to be a tree while you are singing.",
                              "Let's sing with motions.",
                              "Spring, Summer, Fall, Winter",
                              "Spring, Summer, Fall, Winter",
                              "These are the four seasons.",
                              "Spring, Summer, Fall, Winter",
                            ],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_110.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        videoTitle: "sight words can make",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_111.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "special days",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-09-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        videoTitle: "hidden pictures(k3-09)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book9/k3_v9_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "green",
                        videoTitle: "stretching song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "rain rain go away",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "lets move(k3-09)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_174.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-16",
                        color: "green",
                        videoTitle: "alphabet memory game",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "pat pat pat",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "i am a super cook story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        videoTitle: "rub the balloon",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "i am a super cook song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "green",
                        videoTitle: "hidden pictures pale pink", // (k3-04)
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book4/k3_v4_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "friendship song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-09.jpg?alt=media&token=94c4f51e-fef9-4d96-b69e-b5ebae9343e5",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-09%20lets%20make.mp4?alt=media&token=eecf4aaa-59fb-41e5-b6c0-a9c126ec0700",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-09.png?alt=media&token=96c5c5d6-59df-428f-b32d-e715919d2c79",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k3/book9/k3_v9.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book9/k3_v9_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-09.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book9/k3_v9_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-09.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-09%20teacher%20supplement.jpg?alt=media&token=3a08ad05-fb70-44bb-892d-9e92524c48b8",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-09%20teacher%20supplement.pdf?alt=media&token=28b21e5a-3610-49c8-87eb-80919bc2d440",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-09%20worksheets.jpg?alt=media&token=05591f0c-3980-434e-a571-6e889218b979",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-09%20worksheets.pdf?alt=media&token=3f4fb738-d9f4-4624-9ba7-0bf32037ecf9",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                color: "orange",
                videoTitle: "i am a super cook story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Song",
                color: "cyan",
                videoTitle: "i am a super cook song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "cover your mouth",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "merry christmas",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "pat pat pat",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                videoTitle: "rain rain go away",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                videoTitle: "seasons song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [
                      "Pretend to be a tree while you are singing.",
                      "Let's sing with motions.",
                      "Spring, Summer, Fall, Winter",
                      "Spring, Summer, Fall, Winter",
                      "These are the four seasons.",
                      "Spring, Summer, Fall, Winter",
                    ],
                  },
                ],
              },
              {
                name: "Routine-8",
                color: "orange",
                videoTitle: "shapes song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "long u(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-3",
                color: "orange",
                imageURL: "",
                videoTitle: "friendship song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [
                      // "Pretend to be a tree while you are singing.",
                      // "Let's sing with motions.",
                      // "Spring, Summer, Fall, Winter",
                      // "Spring, Summer, Fall, Winter",
                      // "These are the four seasons.",
                      // "Spring, Summer, Fall, Winter",
                    ],
                  },
                ],
              },
            ],
          },

          // k3-10
          {
            volume: "10",
            weeks: [
              // 1st week
              {
                name: "1",
                src: "/img/weeks/k3-10-01.jpg",
                color: "cyan",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-13",
                        color: "orange",
                        videoTitle: "friendship song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Calendar 2",
                        color: "cyan",
                        videoTitle: "calendar",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/dailyRoutine/k1/calendar1_html.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "i love earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine-4",
                        color: "orange",
                        videoTitle: "the weather song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-4",
                        color: "cyan",
                        videoTitle: "lets look at the cover(k3-10)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_143.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "the green string",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "orange",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "orange",
                        videoTitle: "hidden pictures dark sky", // (k3-01)
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book1/k3_v1_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "cyan",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "cyan",
                        videoTitle: "hidden pictures sky", // (k3-02)
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book3/k3_v3_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "cyan",
                        videoTitle: "colors song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 2nd week
              {
                name: "2",
                src: "/img/weeks/k3-10-02.jpg",
                color: "pink",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "cyan",
                        videoTitle: "hidden pictures pink", // (k3-03)
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k2/book5/k2_v5_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "pink",
                        videoTitle: "long u(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "the green string",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-10",
                        color: "cyan",
                        videoTitle: "long vowels(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-11",
                        color: "pink",
                        videoTitle: "long vowels",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_144.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "manners",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "cyan",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "cyan",
                        videoTitle: "hidden pictures pale pink", // (k3-04)
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book4/k3_v4_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "pink",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-12",
                        color: "pink",
                        videoTitle: "add fruits",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_145.html",
                      },
                      {
                        name: "Chant",
                        color: "pink",
                        videoTitle: "roll your hands",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 3rd week
              {
                name: "3",
                src: "/img/weeks/k3-10-03.jpg",
                color: "green",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-13",
                        color: "pink",
                        videoTitle: "lets recycle",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book10/k3_v10_activity1.html",
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-16",
                        color: "amber",
                        videoTitle: "build the cup tower",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_175.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "i love earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "long a(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "amber",
                        videoTitle: "long i(chant)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "twinkle twinkle little star",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "pink",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Phonics-18",
                        color: "pink",
                        videoTitle: "the earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "amber",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-19",
                        color: "amber",
                        videoTitle: "sight words little big",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_146.html",
                      },
                      {
                        name: "Chant",
                        color: "amber",
                        videoTitle: "manners",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              // 4th week
              {
                name: "4",
                src: "/img/weeks/k3-10-04.jpg",
                color: "amber",
                show: false,
                rounds: [
                  // 1st round
                  {
                    color: "indigo--text text--lighten-3",
                    element: 3,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "amber",
                        videoTitle: "hidden pictures(k3-10)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book10/k3_v10_activity2.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Routine",
                        color: "green",
                        videoTitle: "clean up song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "the green string",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 2nd round
                  {
                    color: "indigo--text text--lighten-1",
                    element: 5,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-22",
                        color: "amber",
                        videoTitle: "lets move Robby(k3-10)",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/100/100_147.html",
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "ABC Song",
                        color: "green",
                        videoTitle: "ABC song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [
                              "Let's sing the ABC Song.",
                              "Do you know the ABC Song?",
                              "Let's sing the ABC Song.",
                              "ABCDEFG, HIJKLMNOP,",
                              "QRS, TUV, WX and Y and Z.",
                              "I say A. You say BC! A, BC! A, BC!",
                              "Now I know my ABCs, ABCs, ABCs!",
                              "Everybody come on and sing the sounds!",
                              "Good job singing.",
                            ],
                          },
                        ],
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "i love earth",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },

                  // 3rd round
                  {
                    color: "indigo--text text--darken-2",
                    element: 4,
                    steps: [
                      {
                        name: "Story",
                        color: "amber",
                        videoTitle: "if all the world story",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Activity-14",
                        color: "amber",
                        videoTitle: "music maker",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Song",
                        color: "green",
                        videoTitle: "if all the world song",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                      {
                        name: "Hidden Pictures",
                        color: "green",
                        videoTitle: "hidden pictures yellow", // (k3-05)
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],

                        htmlURL:
                          "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book5/k3_v5_activity2.html",
                      },
                      {
                        name: "Chant",
                        color: "green",
                        videoTitle: "twinkle twinkle little star",
                        videoURL: "",
                        teachingGuides: [
                          {
                            sentences: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            steam: {
              show: false,
              links: [
                {
                  title: "Let's Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Flets-make%20K3-10.jpg?alt=media&token=d1c993f4-1119-43f9-bcc7-59915f6621f2",
                  videoURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/videos%2Fk3-10%20lets%20make.mp4?alt=media&token=be5e172d-da6b-4e2d-83e3-27663f5d3858",
                  htmlURL: "",
                },
                {
                  title: "How to Make",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/steam%2Fhow-to-make%20K3-10.png?alt=media&token=7e2c60d5-88d7-4902-8411-6991120cdd2b",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_science/k3/book10/k3_v10.html",
                },
                {
                  title: "Role Playing",
                  imageURL: "/role-playing.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book10/k3_v10_rolePlay.html",
                },
                {
                  title: "Assessment",
                  imageURL: "/img/assessment/K3-10.jpg",
                  videoURL: "",
                  htmlURL:
                    "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/k3/book10/k3_v10_assessment.html",
                },
                {
                  title: "Story Puzzle",
                  imageURL: "/steam-02.jpg",
                  puzzleImg: "/img/puzzles/k3-10.png",
                },
                {
                  title: "Memory Game",
                  imageURL: "/steam-03.jpg",
                  videoURL: "",
                  htmlURL: "",
                },
              ],
            },
            supplement: {
              show: false,
              links: [
                {
                  title: "teacher supplement",
                  color: "purple lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-10%20teacher%20supplement.jpg?alt=media&token=94d09742-3069-4dca-abaf-9fae56008026",
                  URL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-10%20teacher%20supplement.pdf?alt=media&token=24c11562-6400-4e46-97ad-24ab56f9d01d",
                },
                {
                  title: "worksheets",
                  color: "brown lighten-2",
                  imageURL:
                    "https://firebasestorage.googleapis.com/v0/b/iwonder-b5a76.appspot.com/o/supplements%2FK3-10%20worksheets.jpg?alt=media&token=fdb37148-c936-4f57-b05a-7535be12ab46",
                  URL: "",
                },
                {
                  title: "Manual",
                  color: "green lighten-2",
                  imageURL: "",
                  URL: "",
                },
              ],
            },
            parents: [
              {
                name: "Story",
                show: "false",
                show: "false",
                color: "orange",
                videoTitle: "if all the world story",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },

              {
                name: "Song",
                color: "cyan",
                videoTitle: "if all the world song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "i love earth",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "pink",
                videoTitle: "manners",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "amber",
                videoTitle: "twinkle twinkle little star",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Chant",
                color: "cyan",
                imageURL: "",
                videoTitle: "the green string",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine-13",
                color: "orange",
                videoTitle: "friendship song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Routine",
                color: "green",
                videoTitle: "clean up song",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-10",
                color: "cyan",
                videoTitle: "long vowels(chant)",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
              {
                name: "Phonics-18",
                color: "pink",
                videoTitle: "the earth",
                videoURL: "",
                teachingGuides: [
                  {
                    sentences: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    hangeul: [
      // 기초한글
      {
        level: "한글 기초",
        color: "indigo",
        books: [
          // book 1
          {
            title: "즐거운 하루",
            src: "/img/hangeul/k1-01.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㄱ",
                imageURL: "",
                videoTitle: "ㄱ",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기(가~바)",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 노래",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 글자 가",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 즐거운 하루",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 즐거운 하루",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 무엇부터 입어야지",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "우리 반에 왜 왔니",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },

          // book 2
          {
            title: "꼬마야 꼬마야",
            src: "/img/hangeul/k1-02.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㄴ",
                imageURL: "",
                videoTitle: "ㄴ",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기(가~바)",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 노래",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 글자 나",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 꼬마야 꼬마야",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 꼬마야 꼬마야",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 혼자 하게 해주세요",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "곰 세마리",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },

          // book 3
          {
            title: "어디까지 왔나?",
            src: "/img/hangeul/k1-03.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㄷ",
                imageURL: "",
                videoTitle: "ㄷ",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기(가~바)",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 노래",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 글자 다",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 어디까지 왔나",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 어디까지 왔나",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 시장에서",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "병원차와 소방차",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },

          // book 4
          {
            title: "동물들의 인사",
            src: "/img/hangeul/k1-04.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㄹ",
                imageURL: "",
                videoTitle: "ㄹ",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기(가~바)",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 노래",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 글자 라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 동물들의 인사",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 동물들의 인사",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 거북이",
                imageURL: "",
                htmlURL: "",
              },
              {
                songTitle: "씨앗",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },

          // book 5
          {
            title: "이를 닦자 손을 씻자",
            src: "/img/hangeul/k1-05.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅁ",
                imageURL: "",
                videoTitle: "ㅁ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅂ",
                imageURL: "",
                videoTitle: "ㅂ",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기(가~바)",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 노래",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 글자 마바",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 이를 닦자 손을 씻자",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 이를 닦자 손을 씻자",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 목욕탕에서",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "건너가는 길",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },

          // book 6
          {
            title: "도깨비 나라",
            src: "/img/hangeul/k1-06.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅅ",
                imageURL: "",
                videoTitle: "ㅅ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅇ",
                imageURL: "",
                videoTitle: "ㅇ",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "모양 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 글자 사아",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 도깨비 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 도깨비 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 쓱쓱 싹싹",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "똑딱똑딱",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },

          // book 7
          {
            title: "원숭이 엉덩이는 빨개",
            src: "/img/hangeul/k1-07.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅈ",
                imageURL: "",
                videoTitle: "ㅈ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅊ",
                imageURL: "",
                videoTitle: "ㅊ",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "모양 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 글자 자차",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 원숭이 엉덩이는 빨개",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 원숭이 엉덩이는 빨개",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 무슨 소리가 들리니",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "씽씽 쌩쌩",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },

          // book 8
          {
            title: "아름다운 우리나라",
            src: "/img/hangeul/k1-08.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅌ",
                imageURL: "",
                videoTitle: "ㅋ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅌ",
                imageURL: "",
                videoTitle: "ㅌ",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "모양 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 글자 카타",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 아름다운 우리나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 아름다운 우리나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 고맙습니다",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "우리나라 꽃",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },

          // book 9
          {
            title: "물은 요술쟁이",
            src: "/img/hangeul/k1-09.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅍ",
                imageURL: "",
                videoTitle: "ㅍ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "ㅎ",
                imageURL: "",
                videoTitle: "ㅎ",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "모양 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 글자 파하",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 물은 요술쟁이",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 물은 요술쟁이",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 소꿉놀이",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "밀과 보리가 자라네",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },

          // book 10
          {
            title: "겨울에는",
            src: "/img/hangeul/k1-10.jpg",
            contents: [
              {
                sceneTitle: "가나다 노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "요일 노래",
                imageURL: "",
                videoTitle: "요일 노래",
                videoURL: "",
                htmlURL: "",
              },
              // mp3
              {
                songTitle: "가나다 이야기",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "숫자 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "색깔 나라",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "재미있는 가나다",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "그림책 겨울에는",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "노래 겨울에는",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "동시 나란히 나란히",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
              {
                songTitle: "사계절",
                imageURL: "",
                mp3URL: "",
                htmlURL: "",
              },
            ],
          },
        ],
      },

      // 한글밥상 1
      {
        level: "한글밥상 2",
        color: "grey",
        books: [
          // book 1
          {
            title: "이서방",
            src: "/img/hangeul/k2-01.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "이서방",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "ㄱ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "ㄴ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "ㄷ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 2
          {
            title: "뒤죽박죽 동물",
            src: "/img/hangeul/k2-02.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "뒤죽박죽 동물",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "ㄹ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "ㅁ",
                videoURL: "",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "ㅂ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 3
          {
            title: "해와 달이 된 오누이",
            src: "/img/hangeul/k2-03.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "해와 달이 된 오누이",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "ㅅ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "ㅇ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 4
          {
            title: "숫자풀이",
            src: "/img/hangeul/k2-04.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "숫자풀이",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "ㅈ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "ㅊ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "ㅋ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 5
          {
            title: "통김치 이야기",
            src: "/img/hangeul/k2-05.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "통김치 이야기",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "가나다 노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "ㅌ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "ㅍ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "ㅎ",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 6
          {
            title: "초록끈",
            src: "/img/hangeul/k2-06.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "초록끈",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "네거리 놀이",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "그림찾기 ㄱ ㄴ ㄷ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek4.html",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "그림찾기 ㄹ ㅁ ㅂ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek3.html",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "그림찾기 ㅅ ㅇ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek5.html",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "그림찾기 ㅈ ㅊ ㅋ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek8.html",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "그림찾기 ㅌ ㅍ ㅎ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek14.html",
              },
            ],
          },

          // book 7
          {
            title: "추석 송편 이야기",
            src: "/img/hangeul/k2-07.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "추석 송편 이야기",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "꼬방 꼬방",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "그림찾기 ㄲ ㄸ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek2.html",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "그림찾기 ㅃ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek6.html",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "그림찾기 ㅆ ㅉ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek7.html",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 8
          {
            title: "무늬가 보이나요?",
            src: "/img/hangeul/k2-08.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "무늬가 보이나요",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "잠자리 꽁꽁",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "그림찾기 받침 ㄱ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek1.html",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "그림찾기 받침 ㄴ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek9.html",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "요일노래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 9
          {
            title: "비석치기",
            src: "/img/hangeul/k2-09.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "비석치기",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "두껍아 두껍아",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "그림찾기 받침 ㄷ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek10.html",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "그림찾기 받침 ㄹ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek11.html",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 10
          {
            title: "우리 집 옷 이야기",
            src: "/img/hangeul/k2-10.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "우리 집 옷 이야기",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "실구대 소리",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "음소활동 1",
                imageURL: "",
                videoTitle: "그림찾기 받침 ㅁ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek12.html",
              },
              {
                sceneTitle: "음소활동 2",
                imageURL: "",
                videoTitle: "그림찾기 받침 ㅇ",
                videoURL: "",
                htmlURL:
                  "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/seek/seek13.html",
              },
              {
                sceneTitle: "음소활동 3",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "카드 뒤집기",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },
        ],
        mp3: [
          {
            part: "1학기 음원",
            imageURL: "/mp3-2-1.jpg",
            content: [
              {
                songTitle: "가나다 노래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "이서방",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "동물 흉내",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "뒤죽박죽 동물",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "엄지 어디 있소",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "해와 달이 된 오누이",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "비야 비야",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "우리 마을",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "숫자풀이",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "내가 좋아하는 음식",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "통김치 이야기",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "리리리자로",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "요일 노래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "날씨 노래",
                imageURL: "",
                mp3URL: "",
              },
            ],
          },
          {
            part: "2학기 음원",
            imageURL: "mp3-2-2.jpg",
            content: [
              {
                songTitle: "네거리 놀이",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "초록끈",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "음소 노래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "꼬방 꼬방",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "추석 송편 이야기",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "잠자리 꽁꽁",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "무늬가 보이나요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "두껍아 두껍아",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "비석치기",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "실구대 소리",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "우리 집 옷 이야기",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "눈이 내리면",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "요일 노래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "날씨 노래",
                imageURL: "",
                mp3URL: "",
              },
            ],
          },
        ],
      },

      // 한글밥상 2
      {
        level: "한글밥상 3",
        color: "brown",
        books: [
          // book 1
          {
            title: "깍깍 아침까치 깍깍깍",
            src: "/img/hangeul/k3-01.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "깍깍 아침까치 깍깍깍",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "우리는 친한 친구",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "안녕",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "아리랑",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 2
          {
            title: "하아움",
            src: "/img/hangeul/k3-02.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "하아움",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "어느 날 밤에",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "호랑나비",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "여우야",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 3
          {
            title: "두근두근",
            src: "/img/hangeul/k3-03.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "두근두근",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "우리 가족",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "할아버지 시계",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "자장자장",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 4
          {
            title: "행복을 주는 작은 공원",
            src: "/img/hangeul/k3-04.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "행복을 주는 작은 공원",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "누가 도와주시나요",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "우리 동네",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "퐁당퐁당",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 5
          {
            title: "처얼썩",
            src: "/img/hangeul/k3-05.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "처얼썩",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "떡갈나무와 비",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "바다는 음악가",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "여름 냇가",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 6
          {
            title: "어기야디야 어기야차",
            src: "/img/hangeul/k3-06.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "어기야디야 어기야차",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "열기구 여행",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "여행",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "쾌지나 칭칭 나네",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 7
          {
            title: "둥둥둥",
            src: "/img/hangeul/k3-07.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "둥둥둥",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "우리집에 오세요",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "꼭꼭 숨어라",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "대한민국",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 8
          {
            title: "훨훨",
            src: "/img/hangeul/k3-08.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "훨훨",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "나뭇잎의 세상 여행",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "귀뚜라미 우는 밤",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "강강수월래",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 9
          {
            title: "초대합니다",
            src: "/img/hangeul/k3-09.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "초대합니다",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "마법의 도구",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "비밀창고",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "개구리",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
            ],
          },

          // book 10
          {
            title: "눈 내리는 날",
            src: "/img/hangeul/k3-10.jpg",
            contents: [
              {
                sceneTitle: "그림책",
                imageURL: "",
                videoTitle: "눈 내리는 날",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "생활동화",
                imageURL: "",
                videoTitle: "겨울잠을 자요",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "노래",
                imageURL: "",
                videoTitle: "눈이 옵니다",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "포스터 낱말",
                imageURL: "",
                videoTitle: "꼭꼭 숨어라",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "퍼즐 1",
                imageURL: "",
                videoTitle: "",
                videoURL: "",
                htmlURL: "",
              },
              {
                sceneTitle: "육하원칙 카드",
                imageURL: "",
                videoTitle: "졸업식 전날 밤",
                videoURL: "",
              },
            ],
          },
        ],
        mp3: [
          {
            part: "1학기 음원",
            imageURL: "mp3-3-1.jpg",
            content: [
              {
                songTitle: "안녕",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "아침까지 깍깍깍",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "아리랑",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "우리는 친한 친구",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "내 친구는 어디 있나요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "내가 할 수 있는 것",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "호랑나비",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "하아움",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "여우야",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "어느 날 밤에",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "어떤 동물 일까요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "친구하려고",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "할아버지 시계",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "두근두근",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "자장자장",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "우리가족",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "우리 가족은 어디에 있나요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "오케스트라 가족",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "우리동네",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "행복을 주는 작은 공원",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "퐁당퐁당",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "누가 도와 주시나요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "어디 일까요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "어서어서 자라라",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "바다는 음악가",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "처얼썩",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "여름냇가",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "떡갈나무와 비",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "나는 누구일까요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "그림자",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "요일 노래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "날씨 노래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "음소 노래",
                imageURL: "",
                mp3URL: "",
              },
            ],
          },
          {
            part: "2학기 음원",
            imageURL: "mp3-3-2.jpg",
            content: [
              {
                songTitle: "여행",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "어기야디야 어기여차",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "쾌지나 칭칭나네",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "열기구 여행",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "따라가 보세요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "갈 수 있어요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "흥겹게 노래를 불러 보세요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "둥둥둥",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "대한민국",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "우리집에 오세요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "어느 나라일까요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "노래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "귀뚜라미 우는 밤",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "훨훨",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "강강수월래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "나뭇잎의 세상여행",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "나는 누구일까요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "바스락 부스럭",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "마법의 도구",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "초대합니다",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "꼭꼭 숨어라",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "비밀창고",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "필요해요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "꼭 필요해요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "눈이 옵니다",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "눈 내리는 날",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "개구리",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "겨울잠을 자요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "어디에 있나요",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "고드름",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "요일 노래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "날씨 노래",
                imageURL: "",
                mp3URL: "",
              },
              {
                songTitle: "음소 노래",
                imageURL: "",
                mp3URL: "",
              },
            ],
          },
        ],
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
});
