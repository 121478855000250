var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hangeul"},[_c('v-container',[(_vm.levelOfKorean.includes('한글1'))?_c('v-row',{staticClass:"mb-3"},_vm._l((_vm.levels[0].books),function(book,index){return _c('v-col',{key:index,staticClass:"pa-1",attrs:{"cols":"4","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('router-link',{attrs:{"to":{
                name: 'HangeulDetails',
                params: {
                  level: _vm.levels[0].level,
                  level_volume: `k1-0${index + 1}`,
                  book: book,
                },
              }}},[_c('v-img',{attrs:{"src":book.src}})],1)],1)]}}],null,true)})],1)}),1):_vm._e(),(_vm.levelOfKorean.includes('한글2'))?_c('v-row',{staticClass:"mb-3"},[_vm._l((_vm.levels[1].books),function(book,index){return _c('v-col',{key:index,staticClass:"pa-1",attrs:{"cols":"4","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('router-link',{attrs:{"to":{
                name: 'HangeulDetails',
                params: {
                  level: _vm.levels[1],
                  level_volume: `k2-0${index + 1}`,
                  book: book,
                },
              }}},[_c('v-img',{attrs:{"src":book.src}})],1)],1)]}}],null,true)})],1)}),_vm._l((_vm.levels[1].mp3),function(part){return _c('v-col',{key:part.part,staticClass:"pa-1",attrs:{"cols":"4","md":"3"}},[_c('Mp3Modal',{attrs:{"part":part}})],1)})],2):_vm._e(),(_vm.levelOfKorean.includes('한글3'))?_c('v-row',[_vm._l((_vm.levels[2].books),function(book,index){return _c('v-col',{key:index,staticClass:"pa-1",attrs:{"cols":"4","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('router-link',{attrs:{"to":{
                name: 'HangeulDetails',
                params: {
                  level: _vm.levels[2],
                  level_volume: `k3-0${index + 1}`,
                  book: book,
                },
              }}},[_c('v-img',{attrs:{"src":book.src}})],1)],1)]}}],null,true)})],1)}),_vm._l((_vm.levels[2].mp3),function(part){return _c('v-col',{key:part.part,staticClass:"pa-1",attrs:{"cols":"4","md":"3"}},[_c('Mp3Modal',{attrs:{"part":part}})],1)})],2):_vm._e(),(_vm.member == '부모님')?_c('v-row',[_c('v-col',[_vm._v("선생님만 보실 수 있습니다")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }