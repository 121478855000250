<template>

  <v-footer dark padless>

    <v-card
      width="100%"
      flat
      tile
      class="grey darken-3 white--text text-center"
    >

      <v-card-text>

        <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>

          <v-icon size="24px"> {{ icon }} </v-icon>

        </v-btn>

      </v-card-text>

      <v-card-text>
         경기도 성남시 분당구 벌말로40번길 4, 203호 | 전화 : 031-702-7058 | iwonderp@hanmail.net
        | 사업자번호 : 110-92-60519
      </v-card-text>

      <v-card-text class="white--text">
         Copyright {{ new Date().getFullYear() }} All Rights Reserved —
        <strong>IWonder</strong>

      </v-card-text>

    </v-card>

  </v-footer>

</template>

<script>
 export default { data() { return { icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin",
"mdi-instagram"], }; }, };
</script>

<style></style>

